import type { FC } from "react";

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Loader } from "@relatable/ui/Loader";
import { Table } from "@relatable/ui/Table";
import { useNavigate } from "react-router-dom";

import { useAdminClientsQuery } from "./generated";

export const Admin: FC = () => {
  const { data } = useAdminClientsQuery();
  const navigate = useNavigate();
  return (
    <SAdmin>
      <div>
        <Typography>
          The client can log in at
          <strong> {process.env.CLIENT_PORTAL_URL} </strong>
          using their email address that we&apos;ve set up in the campaign system
        </Typography>

        <Typography>
          Here you can find a list with all the clients we have in the system.
        </Typography>
      </div>

      {data?.clients ? (
        <Table
          tableId="admin-clients-list"
          onRowClick={row => navigate(`/client/${row.hubspot_company_id}`)}
          columns={[
            { field: "order", headerName: "Order" },
            { field: "name", headerName: "Name" },
            { field: "username", headerName: "Username" }
          ]}
          sortable
          rows={data.clients.map((i, index) => ({
            ...i,
            key: i.hubspot_company_id,
            id: i.name,
            order: index + 1
          }))}
        />
      ) : (
        <Loader />
      )}
    </SAdmin>
  );
};

const SAdmin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 100px;

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
    font-size: 1rem;
  }
`;
