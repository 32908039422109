import { useState } from "react";

import styled from "@emotion/styled";
import { ChatBubble, Check, Close } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { clientApproved } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import type { ClientContentApprovalLogFragment } from "../generated";
import { ContentLogs } from "./ContentLogs";
import type { ContentStateFragment } from "./helpers";

interface SidebarReviewProps {
  state: ContentStateFragment;
  mediaState: CONTENT_APPROVAL_STATE | null;
  logs: ClientContentApprovalLogFragment[];
  contentType: "media" | "caption";
  isStory: boolean;
  isVideo: boolean;
  onUpdateState: (state: boolean) => void;
  updateStateLoading: boolean;
  onAddComment: (text: string) => void;
  addCommentLoading: boolean;
}

export const SidebarReview: React.FC<SidebarReviewProps> = ({
  state,
  mediaState,
  logs,
  contentType,
  isStory,
  isVideo,
  onUpdateState,
  updateStateLoading,
  onAddComment,
  addCommentLoading
}) => {
  const [comment, setComment] = useState("");

  if (!state || !mediaState) return null;

  const onSubmit = () => {
    onAddComment(comment);
    setComment("");
  };

  const renderButton = (params: {
    value: "approve" | "decline";
    active: boolean;
    disabled: boolean;
  }) => {
    const onButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (!params.disabled) {
        onUpdateState(params.value === "approve");
      }
    };

    return (
      <Tooltip
        title={(() => {
          if (params.disabled) {
            if (params.value === "decline") return "Adding a comment is necessary before rejecting";
            return "This content was already reviewed";
          }
          return "";
        })()}
      >
        <span>
          {/* <span> enables the tooltip even if the button is disabled */}
          <ReviewIcon
            href="#"
            className={`${params.value} ${params.active ? "active" : ""}`}
            $disabled={params.disabled}
            onClick={onButtonClick}
          >
            <span className={`circle ${params.value}`}>
              {params.value === "approve" ? <Check /> : <Close />}
            </span>
          </ReviewIcon>
        </span>
      </Tooltip>
    );
  };

  const typeLabel = (() => {
    if (contentType === "caption") return "caption";
    if (isStory) return "clip";
    if (isVideo) return "video";
    return "image";
  })();

  const hasClientComment =
    logs.find(l => Boolean(l.textForAdmin || l.textForClient) && l.target === "relatable") !==
    undefined;

  return (
    <Root>
      <div className="header">
        <h4>Is the {typeLabel} approved?</h4>
        <div className="buttons">
          {renderButton({
            value: "approve",
            active: clientApproved(mediaState),
            disabled: updateStateLoading || clientApproved(mediaState)
          })}
          {renderButton({
            value: "decline",
            active: mediaState === CONTENT_APPROVAL_STATE.CLIENT_REJECTED,
            disabled:
              !hasClientComment ||
              updateStateLoading ||
              mediaState === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING ||
              mediaState === CONTENT_APPROVAL_STATE.CLIENT_REJECTED
          })}
        </div>
      </div>

      <textarea
        onChange={e => setComment(e.target.value)}
        rows={6}
        placeholder="Add comment"
        value={comment}
      />

      <Button className="button" disabled={addCommentLoading} onClick={() => onSubmit()}>
        <ChatBubble style={{ marginRight: 8 }} />
        Add Comment
      </Button>

      <ContentLogs logs={logs} />
    </Root>
  );
};

const Root = styled.div`
  > .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;

    > h4 {
      font-size: 18px;
    }
  }

  > textarea {
    width: 100%;
    padding: 12px 8px;
    border: 1px solid ${palette.gray[40]};
    resize: none;
    line-height: 1.2;
  }

  > .button {
    display: block;
    margin-top: 4px;
  }
`;

const ReviewIcon = styled.a<{ $disabled: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  ${({ $disabled }) => ($disabled ? "pointer-events:none; opacity: 0.6;" : "")}

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 20px;
    color: ${palette.gray[60]};
    background: ${palette.gray[10]};
  }

  &.active {
    &.approve > .circle {
      background: ${palette.primary.green};
      color: ${palette.gray.white};
    }
    &.decline > .circle {
      background: ${palette.primary.gold};
      color: ${palette.gray.white};
    }
  }
`;
