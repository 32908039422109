import { useState } from "react";

import styled from "@emotion/styled";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { sortBy, uniqBy } from "@relatable/helpers/index";
import { getUserLabel, getUserProfilePicture } from "@relatable/helpers/user";
import { Avatar } from "@relatable/ui/Avatar";
import { palette } from "@relatable/ui/Palette";

import { PlatformIcon } from "@relatable/ui/src/icons/PlatformIcon";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import type { CampaignsOverviewQuery } from "../generated";
import { ContentItem } from "./ContentItem";

interface UserProps {
  platform: Platform_Enum | null | undefined;
  pmEmail: string | null;
  user: CampaignsOverviewQuery["campaigns"][0]["campaign_users"][0];
  onUpdateState: (
    id: number,
    contentType: "media" | "caption",
    newClientState: CONTENT_APPROVAL_STATE
  ) => void;
  updateStateLoading: boolean;
  onAddComment: (id: number, contentType: "media" | "caption", text: string) => void;
  addCommentLoading: boolean;
}

export const User: React.FC<UserProps> = ({
  platform,
  pmEmail,
  user,
  onUpdateState,
  updateStateLoading,
  onAddComment,
  addCommentLoading
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const username = getUserLabel(user.user);

  const contentSettings = uniqBy(
    user.campaign_preapproval_contents.flatMap(i => {
      if (!i.content_settings) throw Error(`Missing content settings: ${i.id}`);
      return i.content_settings;
    }),
    "id"
  );
  type R = Record<number, (typeof contentSettings)[number] & { name: string }>;
  const sorted = sortBy(contentSettings, "created_at", { direction: "asc" }).reduce<R>(
    (acc, item, index) => {
      return { ...acc, [item.id]: { ...item, index, name: item.name || `Batch ${index + 1}` } };
    },
    {}
  );

  return (
    <Root key={user.id}>
      <button type="button" className="user" onClick={() => setCollapsed(prev => !prev)}>
        {collapsed ? (
          <ArrowDropDown style={{ marginRight: 8 }} />
        ) : (
          <ArrowDropUp style={{ marginRight: 8 }} />
        )}
        <Avatar
          src={getUserProfilePicture(user.user)}
          alt={username}
          className="avatar"
          size={40}
        />
        <strong>{`@${username}`}</strong>
        <span className="platform-icon">
          <PlatformIcon platform={platform} />
        </span>
      </button>

      {!collapsed ? (
        <div className="contents">
          {user.campaign_preapproval_contents.map(content => {
            if (!content.content_settings) throw Error(`Missing content settings: ${content.id}`);
            return (
              <ContentItem
                key={content.id}
                pmEmail={pmEmail}
                content={content}
                contentSettingName={sorted[content.content_settings.id].name}
                onUpdateState={onUpdateState}
                updateStateLoading={updateStateLoading}
                onAddComment={onAddComment}
                addCommentLoading={addCommentLoading}
              />
            );
          })}
        </div>
      ) : null}
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: 48px;

  > .user {
    display: flex;
    align-items: center;
    border: 0;
    background: ${palette.gray.white};
    cursor: pointer;

    > .avatar {
      width: 40px;
      border-radius: 20px;
      margin-right: 8px;
    }

    > .platform-icon {
      margin-left: 4px;
      color: ${palette.gray[60]};
    }
  }

  > .contents {
    margin-top: 20px;
  }
`;
