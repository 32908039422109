import type { CaptionStateFragment, MediaStateFragment } from "@relatable/gql/generated";
import type { Content_Preapproval_Type_Enum } from "@relatable/gql/generated-base";
import {
  clientApproved,
  clientReviewed,
  managerApproved,
  normalizeState
} from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";

export interface ContentStateFragment {
  id: number;
  content_preapproval_caption?: CaptionStateFragment | null | undefined;
  campaign_preapproval_content_medias: MediaStateFragment[];
}

const TYPE_LABELS: Record<Content_Preapproval_Type_Enum, string> = {
  story: "Story",
  photo: "In feed post",
  carousel: "In feed post",
  tiktok: "TikTok",
  video: "Video",
  snap: "Snap",
  youtube: "YouTube"
} as const;

export type CLIENT_CONTENT_APPROVAL_STATE =
  | CONTENT_APPROVAL_STATE
  | "CLIENT_REVIEWING_UPDATED_CONTENT";

export const getTypeLabel = (type: Content_Preapproval_Type_Enum | null | undefined) => {
  if (!type) return "";
  return TYPE_LABELS[type] ?? "";
};

export const getReviewStatus = (state: ContentStateFragment) => {
  const captionReviewed =
    state.content_preapproval_caption === null ||
    clientReviewed(state.content_preapproval_caption?.content_preapproval_logs[0]?.state);

  const mediasReviewed = state.campaign_preapproval_content_medias.every(media =>
    clientReviewed(media.content_preapproval_logs[0]?.state)
  );

  return { captionReviewed, mediasReviewed };
};

export const getContentAggregateState = (
  states: (string | null | undefined)[]
): CLIENT_CONTENT_APPROVAL_STATE => {
  const normalizedStates = states.map(normalizeState);

  if (normalizedStates.length === 0 || normalizedStates.some(state => !managerApproved(state))) {
    return CONTENT_APPROVAL_STATE.MANAGER_REVIEWING;
  }

  if (normalizedStates.every(state => state === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING)) {
    return CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING;
  }

  if (normalizedStates.every(state => clientApproved(state))) {
    return CONTENT_APPROVAL_STATE.CLIENT_APPROVED;
  }

  if (normalizedStates.some(state => state === CONTENT_APPROVAL_STATE.CLIENT_REJECTED)) {
    return CONTENT_APPROVAL_STATE.CLIENT_REJECTED;
  }

  return CONTENT_APPROVAL_STATE.CLIENT_REVIEWING; // went back to Relatable
};

export const getContentState = (
  content: ContentStateFragment
): { state: CLIENT_CONTENT_APPROVAL_STATE; allItemsReviewed: boolean } => {
  const itemStates: (string | null | undefined)[] = [];

  const captionState = content.content_preapproval_caption?.content_preapproval_logs[0]?.state;
  if (captionState) {
    itemStates.push(captionState);
  }

  if (
    Array.isArray(content?.campaign_preapproval_content_medias) &&
    content?.campaign_preapproval_content_medias.length > 0
  ) {
    itemStates.push(
      ...content.campaign_preapproval_content_medias.map(m => m.content_preapproval_logs[0]?.state)
    );
  }

  const reviewStatus = getReviewStatus(content);
  const allItemsReviewed = reviewStatus.captionReviewed && reviewStatus.mediasReviewed;

  return {
    state: getContentAggregateState(itemStates),
    allItemsReviewed
  };
};

export const getStateBackgroundColor = (content: ContentStateFragment): string => {
  const contentState = getContentState(content);
  if (clientApproved(contentState.state)) return palette.primary.green;
  if (contentState.state === CONTENT_APPROVAL_STATE.CLIENT_REJECTED) {
    if (contentState.allItemsReviewed) return palette.secondary.gold;
    return palette.primary.red;
  }
  if (contentState.state === CONTENT_APPROVAL_STATE.CLIENT_REVIEWING) return palette.primary.red;
  return palette.gray[80];
};

export const CONTENT_APPROVAL_STATE_CLIENT_LABELS: Record<CLIENT_CONTENT_APPROVAL_STATE, string> = {
  CREATOR_INITIAL_UPLOAD: "Rejected",
  MANAGER_REVIEWING: "Rejected",
  MANAGER_REJECTED: "Rejected",
  CLIENT_REVIEWING: "Needs Review",
  CLIENT_REVIEWING_UPDATED_CONTENT: "Review updated content", // specific to client app
  CLIENT_REJECTED: "Rejected",
  CLIENT_APPROVED: "Preapproved",
  READY_FOR_PUBLISHING: "Going live"
};
