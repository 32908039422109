import type { Campaign_User_State_Enum, Platform_Enum } from "@relatable/gql/generated-base";

import type { CampaignCreatorsQuery } from "../generated";
import type { ContentThumbnail } from "../types";

interface CreatorDataRowBase {
  campaignUserId: number;
  platformName: string;
  platformId: string | number;
  platformFollowersCount: number;
  url: string;
  profilePicture: string;
  platform: Platform_Enum;
  thumbnails?: ContentThumbnail[];
}

export interface CreatorDataRow extends CreatorDataRowBase {
  clientData: Record<string, { value?: string; link?: string }>;
  clientComment: string;
  state: Campaign_User_State_Enum;
  isStateEditable: boolean;
}

export function getCampaignUserState({
  state_log,
  state
}: {
  state_log: {
    [key in Campaign_User_State_Enum]: string;
  };
  state: Campaign_User_State_Enum;
}): Pick<CreatorDataRow, "state" | "isStateEditable"> {
  if (["approved", "confirmed"].includes(state)) {
    return { state: "approved", isStateEditable: false };
  } else if (["denied", "rejected", "not_interested"].includes(state)) {
    return { state: "rejected", isStateEditable: false };
  } else {
    const clientStateLogsSorted = Object.entries(state_log)
      .sort(([, a], [, b]) => new Date(b).valueOf() - new Date(a).valueOf())
      .filter(([key]) => key.includes("client_"));

    const latestClientState: Campaign_User_State_Enum =
      clientStateLogsSorted.length > 0
        ? (clientStateLogsSorted[0][0] as Campaign_User_State_Enum)
        : state;

    return {
      state: latestClientState,
      isStateEditable: latestClientState === state
    };
  }
}

function getUserData(
  creatorData: CampaignCreatorsQuery["users_in_lists"][number]
): CreatorDataRowBase | null {
  const { user_instagram, user_youtube, user_tiktok } = creatorData;

  if (user_instagram) {
    return {
      campaignUserId: creatorData.campaign_user?.id,
      platformName: user_instagram.username ?? "",
      platformId: user_instagram.instagram_business_account_id ?? "",
      platformFollowersCount: user_instagram.followed_by ?? 0,
      url: `https://www.instagram.com/${user_instagram.username}`,
      profilePicture:
        user_instagram.internal_profile_picture ?? user_instagram.profile_picture ?? "",
      platform: "instagram",
      thumbnails: user_instagram.thumbnails.map(
        ct =>
          ({
            // internal_media_filename column always contains a video field, internal_thumbnail_filename contains a photo
            isVideo: Boolean(ct.internal_media_filename),
            thumbnailUrl: ct.thumbnail,
            postUrl: `https://www.instagram.com/p/${ct.shortcode}`
          }) as ContentThumbnail
      )
    } as CreatorDataRowBase;
  }

  if (user_youtube) {
    return {
      campaignUserId: creatorData.campaign_user?.id,
      platformName: user_youtube.channel_title ?? "",
      platformId: user_youtube.channel_id ?? "",
      platformFollowersCount: Number(user_youtube.subscribers || 0),
      url: `https://www.youtube.com/channel/${user_youtube.channel_id}`,
      profilePicture: user_youtube.thumbnail ?? "",
      platform: "youtube",
      thumbnails: user_youtube.youtube_channel_videos.map(
        cv =>
          ({
            isVideo: false,
            thumbnailUrl: cv.video_thumbnail_url,
            postUrl: `https://www.youtube.com/watch?v=${cv.video_id}`
          }) as ContentThumbnail
      )
    } as CreatorDataRowBase;
  }

  if (user_tiktok) {
    return {
      campaignUserId: creatorData.campaign_user?.id,
      platformName: user_tiktok.username ?? "",
      platformId: user_tiktok.username ?? "",
      platformFollowersCount: user_tiktok.followed_by || 0,
      url: `https://tiktok.com/@${user_tiktok.username}/`,
      profilePicture: user_tiktok.profile_picture ?? "n/a",
      platform: "tiktok",
      thumbnails: user_tiktok.thumbnails.map(
        ct =>
          ({
            isVideo: false,
            thumbnailUrl: ct.thumbnail,
            postUrl: `https://www.tiktok.com/@${user_tiktok.username}/video/${ct.shortcode}`
          }) as ContentThumbnail
      )
    } as CreatorDataRowBase;
  }

  return null;
}

export function getCreatorDataRow(
  creatorData: CampaignCreatorsQuery["users_in_lists"][number]
): CreatorDataRow | null {
  const userData = getUserData(creatorData);
  if (!userData) return null;
  if (!creatorData.campaign_user?.state) return null;

  const stateProps = getCampaignUserState({
    state: creatorData.campaign_user.state,
    state_log: creatorData.campaign_user?.state_log
  });

  return {
    ...(userData ?? {}),
    clientData: creatorData.campaign_user?.client_data,
    clientComment: creatorData.campaign_user?.client_comment ?? "",
    ...stateProps
  };
}
