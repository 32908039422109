import styled from "@emotion/styled";
import { ChatBubble, Check, Close, Photo } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { clientApproved, normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";
import { Link, useNavigate } from "react-router-dom";
import type { ContentDetailsQuery } from "../generated";
import { ContentDisplay } from "./ContentDisplay";

interface GalleryItemProps {
  index?: number;
  numItems?: number;
  media?: ContentDetailsQuery["contents"][0]["campaign_preapproval_content_medias"][0];
  caption?: ContentDetailsQuery["contents"][0]["content_preapproval_caption"];
  loading?: boolean;
  mediaId?: string;
}

export const GalleryItem: React.FC<GalleryItemProps> = ({
  index,
  numItems,
  media,
  caption,
  loading,
  mediaId
}) => {
  const navigate = useNavigate();

  const headerSpecifier = (() => {
    const item = media ?? caption;
    const state = normalizeState(item?.content_preapproval_logs[0]?.state);
    if (!item || !state) return "";
    if (clientApproved(state)) return "approved";
    if (state === CONTENT_APPROVAL_STATE.CLIENT_REJECTED) return "declined";
    return "";
  })();

  if (media) {
    const numComments = media.logs.filter(l => Boolean(l.textForAdmin || l.textForClient)).length;
    const isOpen = media.id === Number(mediaId);

    return (
      <Root key={media.id} className={isOpen ? "selected" : ""}>
        <div className={`header ${headerSpecifier}`}>
          <div className="left">
            <Photo style={{ marginRight: 4 }} />
            {(index ?? 0) + 1}/{numItems}
            {numComments ? (
              <>
                <ChatBubble style={{ marginLeft: 8, marginRight: 4 }} />
                {numComments}
              </>
            ) : null}
            {loading ? <CircularProgress size={16} style={{ marginLeft: 8 }} /> : null}
          </div>
          <div className="right">
            {headerSpecifier !== "" ? (
              headerSpecifier === "approved" ? (
                <Check style={{ marginLeft: 8 }} />
              ) : (
                <Close style={{ marginLeft: 8 }} />
              )
            ) : null}
          </div>
        </div>
        <div className="image">
          <div className="wrapper">
            <ContentDisplay
              key={media.id}
              open={isOpen}
              url={media.url ?? undefined}
              thumbnail={media.thumbnail ?? undefined}
              isVideo={media.isVideo ?? undefined}
              loading={loading}
              onClick={
                !isOpen
                  ? e => {
                      e.preventDefault();
                      navigate(`${media.id}`);
                    }
                  : undefined
              }
            />
          </div>
        </div>
        {media.caption?.text ? (
          <div className="stacked-caption">
            <div className="header">Caption</div>
            <div className="text">{media.caption.text}</div>
          </div>
        ) : null}
      </Root>
    );
  }

  if (caption) {
    return (
      <Root key="caption" className={mediaId === "caption" ? "selected" : ""}>
        <div className={`header ${headerSpecifier}`}>
          <div className="left">Caption</div>
          {headerSpecifier !== "" ? (
            <div className="right">{headerSpecifier === "approved" ? <Check /> : <Close />}</div>
          ) : null}
        </div>
        <Link to="caption" className="caption">
          {caption.text}
        </Link>
      </Root>
    );
  }

  return null;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  min-width: 200px;
  max-width: 500px;
  min-height: 300px;
  max-height: 440px;
  margin-right: 16px;
  border: 1px solid ${palette.gray[20]};
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
  background: ${palette.gray.white};

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    min-width: 80%;
    min-height: 500px;
    max-height: 580px;
    overflow: auto;
  }

  > .header {
    height: 40px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    color: ${palette.gray[60]};

    a {
      color: inherit;
    }

    &.approved {
      background: ${palette.primary.green};
      color: ${palette.gray.white};
    }

    &.declined {
      background: ${palette.primary.gold};
      color: ${palette.gray.white};
    }
  }

  > .image {
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 150px;

    > .wrapper {
      position: absolute;
      height: 100%;
    }
  }

  > .caption {
    display: flex;
    flex: 0 0 auto;
    height: calc(100% - 40px);
    padding: 16px;
    font-size: 15px;
    color: ${palette.gray[80]};
    white-space: pre-wrap;
  }

  > .stacked-caption {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    > .header {
      padding: 10px 16px;
      font-size: 16px;
      color: ${palette.gray[60]};
      border-bottom: 1px solid ${palette.gray[20]};
    }

    > .text {
      padding: 10px 16px;
      min-height: 40px;
      font-size: 15px;
      line-height: 25px;
      color: ${palette.gray[80]};
      white-space: pre-wrap;
    }
  }
`;
