import type { FC } from "react";

import styled from "@emotion/styled";
import { MMM_DD } from "@relatable/helpers/date";
import { getUserProfilePicture } from "@relatable/helpers/user";
import { palette } from "@relatable/ui/Palette";

import type { ClientContentApprovalLogFragment } from "../generated";

export const ContentLogs: FC<{
  logs: ClientContentApprovalLogFragment[];
}> = ({ logs }) => {
  const getAuthor = (log: ClientContentApprovalLogFragment) => {
    if (log.campaign_user) {
      return {
        name: log.campaign_user.user?.first_name ?? "",
        picture: getUserProfilePicture(log.campaign_user.user)
      };
    }
    if (log.account) {
      return { name: log.account.full_name ?? "", picture: log.account.profile_picture };
    }
    if (log.client_contact) {
      return { name: log.client_contact.first_name ?? "", picture: log.client_contact.picture };
    }
    return { name: "Somebody", picture: null };
  };

  // max 2 characters
  const getMonogram = (log: ClientContentApprovalLogFragment) => {
    const { name } = getAuthor(log);
    if (!name) return "";
    const parts = name.split(" ") || [];
    if (parts.length === 1) {
      return parts[0][0].toUpperCase();
    }
    return [parts[0][0].toUpperCase(), parts[parts.length - 1][0]?.toUpperCase()].join("");
  };

  const getAction = (log: ClientContentApprovalLogFragment) => {
    if (log.textForAdmin || log.textForClient) {
      return "commented:";
    }
    return null; // unknown action
  };

  return (
    <Root>
      {logs.map(log => {
        // should skip unknown actions
        if (!getAction(log)) return null;
        const text = log.textForAdmin || log.textForClient;
        return (
          <LogItem key={log.id}>
            <div className="action">
              <div className="left">
                {getAuthor(log).picture ? (
                  <img
                    className="profile-pic"
                    alt="Profile"
                    src={getAuthor(log).picture ?? undefined}
                  />
                ) : (
                  <div className="profile-pic placeholder">{getMonogram(log)}</div>
                )}
                <span>
                  <strong>{getAuthor(log).name}</strong>&nbsp;
                  {getAction(log)}
                </span>
              </div>
              <span className="date">{MMM_DD(log.created_at)}</span>
            </div>
            {text ? <div className="text">{text}</div> : null}
          </LogItem>
        );
      })}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: auto;
  margin-top: 32px;
  padding-right: 12px;
  margin-bottom: 20px;
`;

const LogItem = styled.div`
  width: 100%;
  margin-bottom: 24px;

  > .action {
    display: flex;
    min-height: 32px;
    justify-content: space-between;
    align-items: center;

    > .left {
      display: flex;
      align-items: center;

      > .profile-pic {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 16px;
        &.placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${palette.secondary.green};
          color: ${palette.gray.white};
        }
      }
    }
  }

  > .text {
    margin-left: 40px;
    padding: 8px 12px;
    display: block;
    background: ${palette.gray.whiteOff};
    white-space: pre-wrap;
  }
`;
