import { type FC, useCallback, useState } from "react";

import styled from "@emotion/styled";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Route, Routes } from "react-router-dom";

import { Footer } from "./common/Footer";
import { Header } from "./common/Header";
import { User } from "./common/User";
import type { CampaignsOverviewQuery } from "./generated";

export const ContentApproval: FC<{
  platform: Platform_Enum | null | undefined;
  pmEmail: string | null;
  campaignUsers: CampaignsOverviewQuery["campaigns"][0]["campaign_users"];
  onUpdateState: (
    id: number,
    contentType: "media" | "caption",
    newClientState: CONTENT_APPROVAL_STATE
  ) => void;
  updateStateLoading: boolean;
  onAddComment: (id: number, contentType: "media" | "caption", text: string) => void;
  addCommentLoading: boolean;
  onSubmitReview: () => void;
}> = ({
  platform,
  pmEmail,
  campaignUsers,
  onUpdateState,
  updateStateLoading,
  onAddComment,
  addCommentLoading,
  onSubmitReview
}) => {
  const [filterFn, setFilterFn] = useState<(u: typeof campaignUsers) => typeof campaignUsers>(
    () => users => users
  );

  // needs useCallback to avoid infinite loop
  const onChangeFilterFn = useCallback(fn => {
    setFilterFn(() => fn);
  }, []);

  const filteredUsers = filterFn(campaignUsers).filter(
    user => user.campaign_preapproval_contents.length
  );

  return (
    <Root>
      <Routes>
        <Route
          path=":contentId?/*"
          element={
            <Header
              briefUrl="#"
              campaignUsers={campaignUsers}
              onChangeFilterFn={onChangeFilterFn}
            />
          }
        />
      </Routes>
      {filteredUsers.length > 0 ? (
        filteredUsers.map(user => (
          <User
            key={user.id}
            pmEmail={pmEmail}
            user={user}
            platform={platform}
            onUpdateState={onUpdateState}
            updateStateLoading={updateStateLoading}
            onAddComment={onAddComment}
            addCommentLoading={addCommentLoading}
          />
        ))
      ) : (
        <p>Currently there are no creators to review.</p>
      )}
      <Footer campaignUsers={campaignUsers} onSubmitReview={onSubmitReview} />
    </Root>
  );
};

const Root = styled.div`
  padding: 0 36px;
`;
