import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateClientReviewMutationVariables = Types.Exact<{
  projectId: Types.Scalars['Int']['input'];
  set: Types.Project_Client_Review_Set_Input;
}>;


export type UpdateClientReviewMutation = { __typename?: 'Mutation', update_project_client_review?: { __typename?: 'project_client_review_mutation_response', affected_rows: number } | null };

export type SubmitClientReviewMutationVariables = Types.Exact<{
  projectId: Types.Scalars['Int']['input'];
}>;


export type SubmitClientReviewMutation = { __typename?: 'Mutation', slackClientFormSubmission?: void | null, update_project_client_review?: { __typename?: 'project_client_review_mutation_response', affected_rows: number } | null };

export type InsertClientReviewMutationVariables = Types.Exact<{
  object: Types.Project_Client_Review_Insert_Input;
}>;


export type InsertClientReviewMutation = { __typename?: 'Mutation', insert_project_client_review_one?: { __typename?: 'project_client_review', id: number } | null };

export type ClientReviewQueryVariables = Types.Exact<{
  projectId: Types.Scalars['Int']['input'];
}>;


export type ClientReviewQuery = { __typename?: 'Query', project_client_review: Array<{ __typename?: 'project_client_review', id: number, area_of_improvements?: string | null, comments?: string | null, rate_communication?: number | null, rate_execution?: number | null, rate_recommendation?: number | null, rate_results?: number | null, rate_satisfaction?: number | null, rate_selection?: number | null, rate_work_again?: number | null, is_submitted: boolean }> };


export const UpdateClientReviewDocument = gql`
    mutation updateClientReview($projectId: Int!, $set: project_client_review_set_input!) {
  update_project_client_review(where: {project_id: {_eq: $projectId}}, _set: $set) {
    affected_rows
  }
}
    `;
export type UpdateClientReviewMutationFn = Apollo.MutationFunction<UpdateClientReviewMutation, UpdateClientReviewMutationVariables>;

/**
 * __useUpdateClientReviewMutation__
 *
 * To run a mutation, you first call `useUpdateClientReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientReviewMutation, { data, loading, error }] = useUpdateClientReviewMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientReviewMutation, UpdateClientReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientReviewMutation, UpdateClientReviewMutationVariables>(UpdateClientReviewDocument, options);
      }
export type UpdateClientReviewMutationHookResult = ReturnType<typeof useUpdateClientReviewMutation>;
export type UpdateClientReviewMutationResult = Apollo.MutationResult<UpdateClientReviewMutation>;
export type UpdateClientReviewMutationOptions = Apollo.BaseMutationOptions<UpdateClientReviewMutation, UpdateClientReviewMutationVariables>;
export const SubmitClientReviewDocument = gql`
    mutation submitClientReview($projectId: Int!) {
  update_project_client_review(
    where: {project_id: {_eq: $projectId}}
    _set: {is_submitted: true}
  ) {
    affected_rows
  }
  slackClientFormSubmission(projectId: $projectId)
}
    `;
export type SubmitClientReviewMutationFn = Apollo.MutationFunction<SubmitClientReviewMutation, SubmitClientReviewMutationVariables>;

/**
 * __useSubmitClientReviewMutation__
 *
 * To run a mutation, you first call `useSubmitClientReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClientReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClientReviewMutation, { data, loading, error }] = useSubmitClientReviewMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSubmitClientReviewMutation(baseOptions?: Apollo.MutationHookOptions<SubmitClientReviewMutation, SubmitClientReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitClientReviewMutation, SubmitClientReviewMutationVariables>(SubmitClientReviewDocument, options);
      }
export type SubmitClientReviewMutationHookResult = ReturnType<typeof useSubmitClientReviewMutation>;
export type SubmitClientReviewMutationResult = Apollo.MutationResult<SubmitClientReviewMutation>;
export type SubmitClientReviewMutationOptions = Apollo.BaseMutationOptions<SubmitClientReviewMutation, SubmitClientReviewMutationVariables>;
export const InsertClientReviewDocument = gql`
    mutation insertClientReview($object: project_client_review_insert_input!) {
  insert_project_client_review_one(object: $object) {
    id
  }
}
    `;
export type InsertClientReviewMutationFn = Apollo.MutationFunction<InsertClientReviewMutation, InsertClientReviewMutationVariables>;

/**
 * __useInsertClientReviewMutation__
 *
 * To run a mutation, you first call `useInsertClientReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientReviewMutation, { data, loading, error }] = useInsertClientReviewMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertClientReviewMutation(baseOptions?: Apollo.MutationHookOptions<InsertClientReviewMutation, InsertClientReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertClientReviewMutation, InsertClientReviewMutationVariables>(InsertClientReviewDocument, options);
      }
export type InsertClientReviewMutationHookResult = ReturnType<typeof useInsertClientReviewMutation>;
export type InsertClientReviewMutationResult = Apollo.MutationResult<InsertClientReviewMutation>;
export type InsertClientReviewMutationOptions = Apollo.BaseMutationOptions<InsertClientReviewMutation, InsertClientReviewMutationVariables>;
export const ClientReviewDocument = gql`
    query clientReview($projectId: Int!) {
  project_client_review(where: {project_id: {_eq: $projectId}}) {
    id
    area_of_improvements
    comments
    rate_communication
    rate_execution
    rate_recommendation
    rate_results
    rate_satisfaction
    rate_selection
    rate_work_again
    is_submitted
  }
}
    `;

/**
 * __useClientReviewQuery__
 *
 * To run a query within a React component, call `useClientReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientReviewQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useClientReviewQuery(baseOptions: Apollo.QueryHookOptions<ClientReviewQuery, ClientReviewQueryVariables> & ({ variables: ClientReviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientReviewQuery, ClientReviewQueryVariables>(ClientReviewDocument, options);
      }
export function useClientReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientReviewQuery, ClientReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientReviewQuery, ClientReviewQueryVariables>(ClientReviewDocument, options);
        }
export function useClientReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientReviewQuery, ClientReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientReviewQuery, ClientReviewQueryVariables>(ClientReviewDocument, options);
        }
export type ClientReviewQueryHookResult = ReturnType<typeof useClientReviewQuery>;
export type ClientReviewLazyQueryHookResult = ReturnType<typeof useClientReviewLazyQuery>;
export type ClientReviewSuspenseQueryHookResult = ReturnType<typeof useClientReviewSuspenseQuery>;
export type ClientReviewQueryResult = Apollo.QueryResult<ClientReviewQuery, ClientReviewQueryVariables>;