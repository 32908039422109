export function areCreatorsUpdated(campaignId: number, remoteSyncDate: string): boolean {
  const key = `relatable-sync-campaign-${campaignId}`;
  const storedTimestamp = window.localStorage.getItem(key);

  if (storedTimestamp === null) {
    window.localStorage.setItem(key, remoteSyncDate);
    return false;
  }

  const updated = storedTimestamp !== remoteSyncDate;
  if (updated) {
    window.localStorage.setItem(key, remoteSyncDate);
  }
  return updated;
}
