import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { useClientsOverviewQuery } from "hooks/generated";
import { useUser } from "hooks/useUser";

import { InviteCollaboratorModal } from "./common";

export const Header: FC = () => {
  const user = useUser();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hasProjectStubParam = pathname.lastIndexOf("/p/") !== -1;
  const projectStub = hasProjectStubParam
    ? pathname.slice(pathname.lastIndexOf("/p/") + 3).split("/")[0]
    : undefined;

  const { hubspotCompanyId } = useParams<{ hubspotCompanyId?: string }>();

  const {
    data: { clients = [] } = {}
  } = useClientsOverviewQuery({
    variables: {
      ...(hubspotCompanyId && {
        where: { hubspot_company_id: { _eq: hubspotCompanyId } }
      })
    }
  });

  const project = clients.flatMap(c => c.projects).find(cp => cp.stub === projectStub);

  const firstName = user?.admin?.first_name ?? user?.client?.first_name ?? "";
  const lastName = user?.admin?.last_name ?? user?.client?.last_name ?? "";
  return (
    <Root>
      <BrandLogo style={{ lineHeight: "initial" }} to={user?.admin ? "/admin" : "/overview"}>
        <img src="/images/relatable_red.svg" alt="Relatable logo" />
      </BrandLogo>
      {project ? (
        <>
          <Button variant="outlined" onClick={() => setInviteModalOpen(true)}>
            Invite
          </Button>
          <InviteCollaboratorModal
            open={inviteModalOpen}
            closeModal={() => setInviteModalOpen(false)}
            project={project}
          />
        </>
      ) : null}
      {user?.admin || user?.client ? (
        <Button
          style={{ marginLeft: 12 }}
          variant="outlined"
          onClick={async () => {
            const url = new URL(`${process.env.GRAPH_URL}/auth/logout`);
            await fetch(String(url), { credentials: "include" });
            navigate("/login", { replace: true });
          }}
        >
          Logout
        </Button>
      ) : (
        <Button
          style={{ marginLeft: 12 }}
          onClick={() => {
            const p = new URLSearchParams();
            p.set("redirectUrl", window.location.href);
            navigate(`/login?${p}`, { replace: true });
          }}
        >
          Login
        </Button>
      )}
      <span style={{ marginLeft: 12 }}>
        {firstName} {lastName} {user?.admin ? "(admin)" : ""}
      </span>
    </Root>
  );
};
const Root = styled.header`
  display: flex;
  width: 100%;
  height: 64px;
  line-height: 64px;
  padding: 0px 36px;
  align-items: center;
`;

const BrandLogo = styled(Link)`
  margin-right: auto;
  > img {
    height: 23px;
  }
`;
