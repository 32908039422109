import { StrictMode } from "react";

import { createRoot } from "react-dom/client";

import { Root } from "./Root";

let container: HTMLElement | null = null;

// https://stackoverflow.com/a/72139826/11661273
document.addEventListener("DOMContentLoaded", () => {
  console.log(`Client app [${process.env.COMMIT_HASH}] ${process.env.BUILD_DATE}`);

  if (!container) {
    container = document.getElementById("root") as HTMLElement;
    const root = createRoot(container);
    root.render(
      <StrictMode>
        <Root />
      </StrictMode>
    );
  }
});
