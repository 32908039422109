import styled from "@emotion/styled";
import { Navigate, Route, Routes } from "react-router-dom";

import { NotFound } from "components/Layout";

import { ContentApprovalContainer } from "./ContentApproval/ContentApprovalContainer";
import { CreatorsContainer } from "./Creators/CreatorsContainer";

interface CampaignProps {
  campaign: { id: number };
}

export const Campaign = ({ campaign }: CampaignProps): JSX.Element => {
  return (
    <Root className="section">
      <Routes>
        <Route path="creators/*" element={<CreatorsContainer campaignId={campaign.id} />} />
        {/* Legacy URL */}
        <Route path="preapproval" element={<Navigate to="approval" />} />
        <Route path="approval/*" element={<ContentApprovalContainer campaignId={campaign.id} />} />
        <Route element={<NotFound />} />
      </Routes>
    </Root>
  );
};

const Root = styled.div``;
