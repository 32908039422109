import { type FC, useLayoutEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

import { Textfield } from "components/ui/Textfield";

const TEXTFIELD_LINE_HEIGHT = 23;
const SHOWN_LINES = 3;

export const UserRowCommentField: FC<{
  value: string;
  platformName: string;
  disabled: boolean;
  onChange: (value: string) => void;
}> = ({ value, platformName, onChange, disabled }) => {
  const textareaRef = useRef<HTMLInputElement>(null);
  const [collapsed, setCollapsed] = useState(true);
  const [enableReadMore, setEnableReadMore] = useState(false);
  const showReadMore = enableReadMore && collapsed;

  useLayoutEffect(() => {
    if (!textareaRef.current) return;
    const el = textareaRef.current;
    const hasScrollbar = el.scrollHeight > TEXTFIELD_LINE_HEIGHT * SHOWN_LINES + 5;
    if (!enableReadMore && hasScrollbar) {
      setEnableReadMore(true);
    }
  }, [enableReadMore]);

  const onReadMoreClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    setCollapsed(false);
  };

  return (
    <Root className="comment-field" onClick={e => e.stopPropagation()}>
      <Textfield
        className="transparent"
        fullWidth={false}
        style={{
          maxHeight: collapsed ? TEXTFIELD_LINE_HEIGHT * SHOWN_LINES : undefined,
          overflow: "hidden",
          justifyContent: "flex-start"
        }}
        id={`${platformName}-client_comment`}
        multiline
        name="client_comment"
        value={value}
        {...(disabled ? { disabled } : { placeholder: "Add comment" })}
        onChange={e => onChange(e.target.value)}
        onFocus={() => setCollapsed(false)}
        onBlur={() => setCollapsed(true)}
        maxRows={50}
        inputRef={textareaRef}
      />
      {showReadMore ? (
        <ReadMoreLink href="#" onClick={onReadMoreClick}>
          ...read more
        </ReadMoreLink>
      ) : null}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReadMoreLink = styled.a`
  color: ${palette.gray.black};
  font-size: 15px;
  padding: 0 8px;
  &:hover {
    color: ${palette.gray[60]};
  }
`;
