import styled from "@emotion/styled";

export const UseBiggerScreen = (): JSX.Element => {
  return (
    <Root>
      <img src="/images/relatable_red.svg" alt="Relatable logo" style={{ height: 50 }} />
      <span>Please view this page on a bigger screen</span>
    </Root>
  );
};

const Root = styled.main`
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`;
