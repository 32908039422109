import type { FC } from "react";

import { Route, Routes } from "react-router-dom";

import type { ContentDetailsQuery } from "../generated";
import { GalleryItem } from "./GalleryItem";
import { GalleryMediaId } from "./GalleryMediaId";

export const Gallery: FC<{
  medias: ContentDetailsQuery["contents"][0]["campaign_preapproval_content_medias"];
  caption: ContentDetailsQuery["contents"][0]["content_preapproval_caption"];
}> = props => {
  return (
    <Routes>
      <Route
        path=":mediaId/*"
        element={<GalleryMediaId medias={props.medias} caption={props.caption} />}
      />
      <Route
        index
        element={
          <>
            {props.medias.map((media, index) => (
              <GalleryItem
                key={media.id}
                media={media}
                index={index}
                numItems={props.medias.length}
              />
            ))}
            {props.caption ? <GalleryItem caption={props.caption} /> : null}
          </>
        }
      />
    </Routes>
  );
};
