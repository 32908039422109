import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { type MeQuery, useMeQuery } from "hooks/generated";

export const useUser = (): MeQuery["me"] | null => {
  const navigate = useNavigate();
  const redirectOnError = useCallback(
    completedData => {
      if (!completedData.me?.admin && !completedData.me?.client) {
        const params = new URLSearchParams();
        const { origin, pathname, search } = window.location;
        if (!pathname.startsWith("/login")) {
          const redirectPath = !pathname || pathname === "/" ? "/overview" : `${pathname}${search}`;

          params.set("redirectUrl", `${origin}${redirectPath}`);
          navigate(`/login?${params}`, { replace: true });
        }
      }
    },
    [navigate]
  );

  const { data, loading } = useMeQuery({
    onCompleted: completedData => {
      redirectOnError(completedData);
    },
    // onCompleted is only called when there is a GQL error... it doesn't get called for HTTP errors...
    onError: err => {
      console.error(err);
      redirectOnError({});
    }
  });

  if (loading) {
    return null;
  }

  if (!data?.me) {
    return { admin: null, client: null };
  }

  return { ...data.me };
};
