import { type FC, Fragment } from "react";

import styled from "@emotion/styled";
import type { Clients_Bool_Exp, InputMaybe } from "@relatable/gql/generated-base";
import { Loader } from "@relatable/ui/Loader";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import { type BreadcrumbItem, Breadcrumbs } from "@relatable/ui/Breadcrumbs";
import { useSearchParams } from "@relatable/ui/hooks/useSearchParams";
import { Container, NotFound } from "components/Layout";
import { type ClientsOverviewQuery, useClientsOverviewQuery } from "hooks/generated";
import { useUser } from "hooks/useUser";

import { Project } from "./Project";
import { ProjectTile } from "./ProjectTile";

const campaignSections = [{ key: "creators", title: "Creators" }];

export const Overview: FC = () => {
  const user = useUser();
  const { pathname } = useLocation();
  const { project: projectSearchParam = "" } = useSearchParams();
  const { hubspotCompanyId } = useParams<{ hubspotCompanyId?: string }>();

  const projectStub = pathname.split("/p/")?.[1]?.split("/")?.[0];
  const where: InputMaybe<Clients_Bool_Exp> = {};
  if (hubspotCompanyId) where.hubspot_company_id = { _eq: hubspotCompanyId };
  if (projectStub) where.projects = { stub: { _eq: projectStub } };
  const {
    loading: getClientLoading,
    data: { clients = [] } = {}
  } = useClientsOverviewQuery({
    variables: { where },
    // this page shouldn't be used by admins without hubspotCompanyId
    skip: !hubspotCompanyId && !user?.client && !projectStub
  });

  if (!user) {
    return null;
  }

  // clients use a different url pattern, without hubspotCompanyId in it
  if (!user.admin && hubspotCompanyId) {
    return <Navigate to="/" />;
  }

  if (getClientLoading)
    return (
      <Container>
        <Loader />
      </Container>
    );

  const projects = clients.flatMap(c => c.projects);

  const clientCampaigns = projects.reduce<
    ClientsOverviewQuery["clients"][number]["projects"][number]["campaigns"]
  >(
    (acc, project) => [
      ...acc,
      ...(!projectSearchParam || project.stub === projectSearchParam ? project.campaigns : [])
    ],
    []
  );

  const getBreadcrumbsItems = () => {
    const BreadcrumbsItems: BreadcrumbItem[] = [];
    const rootPath = hubspotCompanyId ? `/client/${hubspotCompanyId}` : "/overview";

    const projectRoute = projects.find(p => pathname.includes(`/p/${p.stub}`));
    BreadcrumbsItems.push({
      title: "All projects",
      path: rootPath
    });

    if (projectRoute) {
      const projectPath = `${rootPath}/p/${projectRoute.stub}`;
      BreadcrumbsItems.push({
        title: projectRoute.name,
        path: projectPath,
        dropdownItems: projects.map(p => ({
          title: p.name,
          path: `${rootPath}/p/${p.stub}`
        }))
      });

      const campaignRoute = clientCampaigns.find(c => pathname.includes(`/c/${c.stub}`));
      if (campaignRoute) {
        const campaignPath = `${projectPath}/c/${campaignRoute.stub}`;
        BreadcrumbsItems.push({
          title: campaignRoute.title,
          isLink: false
        });

        const campaignSection = campaignSections.find(
          cs => pathname === `${campaignPath}/${cs.key}`
        );

        if (campaignSection) {
          BreadcrumbsItems.push({
            title: campaignSection.title,
            isLink: false
          });
        }
      }
    }

    return BreadcrumbsItems;
  };

  const getRootComponent = () => {
    if (user.admin) {
      return "Admins should access the Workspace through direct links from the campaign system.";
    }

    return clients.map(c => (
      <Fragment key={c.name}>
        <Container style={{ marginBottom: 40 }}>
          <h2>{c.name}</h2>
        </Container>

        {c.projects.length !== 0 ? (
          c.projects
            .filter(p => (!projectSearchParam ? true : p.stub === projectSearchParam))
            .map((p, index) => (
              <div key={p.id}>
                <ProjectTile withAccordion project={p} />
                {c.projects.length !== index + 1 ? (
                  <Container>
                    <hr />
                  </Container>
                ) : null}
              </div>
            ))
        ) : (
          <Container>No projects yet</Container>
        )}
      </Fragment>
    ));
  };

  return (
    <>
      <Breadcrumbs style={{ paddingLeft: 36 }} defaultExpanded items={getBreadcrumbsItems()} />
      <Root className="section">
        <Routes>
          <Route index element={<>{getRootComponent()}</>} />
          <Route path="p/:projectStub/*" element={<Project />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Root>
    </>
  );
};

const Root = styled.div`
  margin-top: 20px;
  overflow-x: hidden; /* required for the transition */
  position: relative;
`;
