import type { FC } from "react";

import styled from "@emotion/styled";
import LinkIcon from "@mui/icons-material/Link";
import { managerApproved } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";
import { Link, Route, Routes, useNavigate } from "react-router-dom";

import { StatusChip } from "components/ui/StatusChip";
import { useUser } from "hooks/useUser";

import { ChatBubble } from "@mui/icons-material";
import { type CampaignsOverviewQuery, useContentDetailsQuery } from "../generated";
import { ContentItemDetails } from "./ContentItemDetails";
import {
  CONTENT_APPROVAL_STATE_CLIENT_LABELS,
  getContentState,
  getStateBackgroundColor,
  getTypeLabel
} from "./helpers";

export const ContentItem: FC<{
  pmEmail: string | null;
  content: CampaignsOverviewQuery["campaigns"][0]["campaign_users"][0]["campaign_preapproval_contents"][0];
  onUpdateState: (
    id: number,
    contentType: "media" | "caption",
    newClientState: CONTENT_APPROVAL_STATE
  ) => void;
  updateStateLoading: boolean;
  contentSettingName: string;
  onAddComment: (id: number, contentType: "media" | "caption", text: string) => void;
  addCommentLoading: boolean;
}> = ({
  pmEmail,
  content,
  onUpdateState,
  updateStateLoading,
  onAddComment,
  addCommentLoading,
  contentSettingName
}) => {
  const navigate = useNavigate();
  const me = useUser();
  const {
    data: { contents = [] } = {}
  } = useContentDetailsQuery({
    variables: { contentId: content.id, isAdmin: Boolean(me?.admin) },
    skip: true
  });

  const contentDetails = contents.filter(c => managerApproved(getContentState(c).state))[0];

  const onOpenContent = () => {
    navigate(`${content.id}`);
  };

  const filteredMedias = (contentDetails?.campaign_preapproval_content_medias ?? []).filter(m =>
    managerApproved(m.content_preapproval_logs[0]?.state)
  );

  const mediaCommentsNum =
    filteredMedias.reduce(
      (acc, media) =>
        acc + media.logs.filter(l => Boolean(l.textForAdmin || l.textForClient)).length,
      0
    ) ?? 0;

  const captionCommentsNum =
    contentDetails?.content_preapproval_caption?.logs.filter(l =>
      Boolean(l.textForAdmin || l.textForClient)
    ).length ?? 0;

  const contentState = getContentState(content).state;

  const wasReviewedBefore = (() => {
    if (
      content.campaign_preapproval_content_medias.some(m =>
        m.state_history.find(h => h.state === CONTENT_APPROVAL_STATE.MANAGER_REJECTED)
      )
    ) {
      return true;
    }

    if (
      content.content_preapproval_caption?.state_history.find(
        h => h.state === CONTENT_APPROVAL_STATE.MANAGER_REJECTED
      )
    ) {
      return true;
    }

    return false;
  })();

  return (
    <Root>
      <div
        className="header"
        // biome-ignore lint/a11y/useSemanticElements: <explanation>
        role="button"
        tabIndex={0}
        onKeyDown={onOpenContent}
        onClick={onOpenContent}
      >
        <div className="left">
          <div className="cell" style={{ minWidth: 130 }}>
            <strong>{getTypeLabel(content.content_settings?.type)}</strong>
          </div>

          <div className="cell" style={{ minWidth: 230, padding: "10px 16px" }}>
            <StatusChip
              label={
                CONTENT_APPROVAL_STATE_CLIENT_LABELS[
                  contentState === CONTENT_APPROVAL_STATE.CLIENT_REVIEWING && wasReviewedBefore
                    ? "CLIENT_REVIEWING_UPDATED_CONTENT"
                    : contentState
                ]
              }
              backgroundColor={getStateBackgroundColor(content)}
              color={palette.gray.white}
            />
          </div>

          <div className="cell" style={{ minWidth: 220 }}>
            <span style={{ marginRight: 5 }}>{contentSettingName}</span>
          </div>

          {mediaCommentsNum + captionCommentsNum > 0 ? (
            <div className="cell">
              <ChatBubble style={{ marginRight: 4 }} /> {mediaCommentsNum + captionCommentsNum}
            </div>
          ) : null}

          {content.link ? (
            <div className="cell">
              <Link to={content.link} target="_blank" rel="noreferrer">
                <LinkIcon style={{ marginRight: 4 }} /> 1
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      <Routes>
        <Route
          path={`${content.id}/*`}
          element={
            <ContentItemDetails
              pmEmail={pmEmail}
              content={content}
              onUpdateState={onUpdateState}
              updateStateLoading={updateStateLoading}
              onAddComment={onAddComment}
              addCommentLoading={addCommentLoading}
            />
          }
        />
      </Routes>
    </Root>
  );
};

const Root = styled.div`
  border: 1px solid ${palette.gray[20]};
  border-radius: 8px;
  margin-bottom: 20px;

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 52px;

    > .left {
      display: flex;
      align-items: center;

      > .cell {
        padding: 16px;
        border-right: 1px solid ${palette.gray[20]};
        > a {
          color: ${palette.gray[80]};
          &:hover {
            color: ${palette.gray[60]};
          }
        }
      }
    }
  }
`;
