import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { MMM_DD_YYYY, isPast } from "@relatable/helpers/date";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";

import { QuotaIndicator, SubmitReviewButton } from ".";
import type { IFilterOptions, IFilterStateValue } from "../Creators";

interface TableActionBarProps {
  dueDate?: string;
  filter: IFilterStateValue;
  filterOptions: IFilterOptions;
  listNeedsSubmit: boolean;
  minCreators: number;
  onCloseSidebar: () => void;
  onSubmitList: (forceSubmit: boolean, feedback?: string) => void;
  setFilter: (filter: IFilterStateValue) => void;
  submitClientListLoading: boolean;
}

export const TableActionBar = (props: TableActionBarProps): JSX.Element => {
  const {
    dueDate,
    filter,
    filterOptions,
    listNeedsSubmit,
    minCreators,
    onCloseSidebar,
    onSubmitList,
    setFilter,
    submitClientListLoading
  } = props;

  const renderDueDate = () => {
    if (!dueDate) return null;
    const deadlinePassed = isPast(dueDate);
    const className = `due-date ${deadlinePassed ? "red" : ""}`;
    return <span className={className}>Due {MMM_DD_YYYY(dueDate)}</span>;
  };

  return (
    <Root>
      {renderDueDate()}
      <h2>Creators</h2>
      <span>Approve candidates that reflect your brand</span>

      <Select
        hideNone
        size="small"
        label=""
        style={{ marginLeft: "auto" }}
        options={Object.keys(filterOptions).map(key => ({
          label: `${filterOptions[key].label} (${filterOptions[key].count})`,
          value: key
        }))}
        value={filter}
        onChange={v => {
          onCloseSidebar();
          setFilter(v as IFilterStateValue);
        }}
      />

      <div className="divider" />

      <Box style={{ alignSelf: "end" }}>
        <QuotaIndicator
          min={minCreators}
          current={filterOptions.client_approved.count}
          total={filterOptions.all.count}
          variant="small"
        />
      </Box>
      <Box ml={2}>
        <SubmitReviewButton
          variant={filterOptions.client_approved.count < minCreators ? "outlined" : "contained"}
          onSubmit={() => onSubmitList(false)}
          listNeedsSubmit={listNeedsSubmit}
          isSaving={submitClientListLoading}
        />
      </Box>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 36px 36px 36px;
  position: relative;
  color: ${palette.gray.black};

  > h2 {
    margin-right: 12px;
  }

  > .divider {
    border-left: 1px solid ${palette.gray[20]};
    height: 40px;
    align-self: center;
    margin: 0 16px;
  }

  > .due-date {
    position: absolute;
    top: -16px;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;

    &.red {
      color: ${palette.primary.red};
    }
  }
`;
