/* eslint no-unused-expressions: 0 */

import { css } from "@emotion/react";
import { palette } from "@relatable/ui/Palette";

export const GlobalStyle = css`
  *,
  *:before,
  *:after,
  .MuiInputBase-root,
  .MuiInputBase-input.MuiInputBase-input,
  .MuiFormLabel-root.MuiFormLabel-root,
  .MuiInputBase-root.MuiInputBase-root,
  .MuiTooltip-tooltip.MuiTooltip-tooltip {
    font-family: "Manrope", "Helvetica Neue", "Arial", "sans-serif";
    letter-spacing: 0em;
  }

  .MuiTooltip-tooltip.MuiTooltip-tooltip {
    border-radius: 0;
    background: ${palette.gray.black};
    max-width: 320px;
    padding: 4px 8px;
  }

  ul {
    margin: 0px;
  }

  html {
    font-size: 15px;
    box-sizing: border-box;
    color: ${palette.gray.black};
  }

  a {
    color: blue;
    text-decoration: none; /* no underline */
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
    color: ${palette.gray.black};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  #app,
  html,
  body {
    margin: 0 auto;
    height: 100%;
  }

  .section {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;
