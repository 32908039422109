import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminClientsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'clients', name?: string | null, hubspot_company_id: string, username?: string | null }> };


export const AdminClientsDocument = gql`
    query adminClients {
  clients(order_by: {created_at: desc}, limit: 1000) {
    name
    hubspot_company_id
    username
  }
}
    `;

/**
 * __useAdminClientsQuery__
 *
 * To run a query within a React component, call `useAdminClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminClientsQuery(baseOptions?: Apollo.QueryHookOptions<AdminClientsQuery, AdminClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminClientsQuery, AdminClientsQueryVariables>(AdminClientsDocument, options);
      }
export function useAdminClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminClientsQuery, AdminClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminClientsQuery, AdminClientsQueryVariables>(AdminClientsDocument, options);
        }
export function useAdminClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminClientsQuery, AdminClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminClientsQuery, AdminClientsQueryVariables>(AdminClientsDocument, options);
        }
export type AdminClientsQueryHookResult = ReturnType<typeof useAdminClientsQuery>;
export type AdminClientsLazyQueryHookResult = ReturnType<typeof useAdminClientsLazyQuery>;
export type AdminClientsSuspenseQueryHookResult = ReturnType<typeof useAdminClientsSuspenseQuery>;
export type AdminClientsQueryResult = Apollo.QueryResult<AdminClientsQuery, AdminClientsQueryVariables>;