import styled from "@emotion/styled";
import { managerApproved } from "@relatable/helpers/approvalLogs";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";
import { Route, Routes } from "react-router-dom";

import { useUser } from "hooks/useUser";

import { type CampaignsOverviewQuery, useContentDetailsQuery } from "../generated";
import { Gallery } from "./Gallery";
import { Sidebar } from "./Sidebar";

interface ContentItemDetailsProps {
  pmEmail: string | null;
  content: CampaignsOverviewQuery["campaigns"][0]["campaign_users"][0]["campaign_preapproval_contents"][0];
  onUpdateState: (
    id: number,
    contentType: "media" | "caption",
    newClientState: CONTENT_APPROVAL_STATE
  ) => void;
  updateStateLoading: boolean;
  onAddComment: (id: number, contentType: "media" | "caption", text: string) => void;
  addCommentLoading: boolean;
}

export const ContentItemDetails: React.FC<ContentItemDetailsProps> = ({
  pmEmail,
  content,
  onUpdateState,
  updateStateLoading,
  onAddComment,
  addCommentLoading
}) => {
  const me = useUser();
  const {
    data: { contents = [] } = {}
  } = useContentDetailsQuery({
    variables: { contentId: content.id, isAdmin: Boolean(me?.admin) },
    skip: !me
  });

  const [contentDetails] = contents;

  const caption = managerApproved(
    contentDetails?.content_preapproval_caption?.content_preapproval_logs[0]?.state
  )
    ? contentDetails.content_preapproval_caption
    : null;

  const medias = contentDetails?.campaign_preapproval_content_medias ?? [];

  return (
    <Root>
      {contentDetails ? (
        <div className="main">
          <div className="left">
            <Gallery medias={medias} caption={caption} />
          </div>
          <div className="right">
            <Routes>
              <Route
                path=":mediaId?/*"
                element={
                  <Sidebar
                    pmEmail={pmEmail}
                    medias={medias}
                    caption={caption}
                    state={contentDetails}
                    numberOfPosts={contentDetails.content_settings?.number_of_posts ?? 0}
                    isStory={content.content_settings?.type === "story"}
                    onUpdateState={onUpdateState}
                    updateStateLoading={updateStateLoading}
                    onAddComment={onAddComment}
                    addCommentLoading={addCommentLoading}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Root>
  );
};

const Root = styled.div`
  > .main {
    min-height: 612px;
    display: flex;
    border-top: 1px solid ${palette.gray[20]};
    > .left {
      width: 66%;
      background: ${palette.gray[10]};
      display: flex;
      align-items: center;
      padding: 24px;
      overflow-x: auto;
      overflow-y: hidden;
    }
    > .right {
      width: 33%;
      padding: 24px;
    }
  }
`;
