import styled from "@emotion/styled";
import type { SvgIconComponent } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

interface StatusChipProps {
  label: string;
  Icon?: SvgIconComponent;
  backgroundColor?: string;
  color?: string;
}

export const StatusChip: React.FunctionComponent<StatusChipProps> = ({
  label,
  backgroundColor,
  Icon,
  color
}) => {
  return (
    <StyledChip
      label={
        <>
          {Icon && <Icon style={{ marginRight: 8, verticalAlign: "sub" }} />}
          {label}
        </>
      }
      style={{
        backgroundColor: backgroundColor || palette.gray[70],
        color: color ?? "#fff"
      }}
    />
  );
};

const StyledChip = styled(Chip)`
  && {
    cursor: inherit;
    height: 32px;
    font-size: inherit;

    > .MuiChip-label {
      display: inline-flex;
      align-items: center;
    }
  }
`;
