import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Accordion } from "@relatable/ui/Accordion";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { Typography } from "@relatable/ui/Typography";
import { Link } from "react-router-dom";

import { useSearchParams } from "@relatable/ui/hooks/useSearchParams";

export const Login: FC = () => {
  const { redirectUrl } = useSearchParams();
  const [email, setEmail] = useState("");
  const snackbar = useSnackbar();
  const [status, setStatus] = useState<"idle" | "loading" | "success">("idle");
  const [adminUrl] = useState(() => {
    const url = new URL(`${process.env.GRAPH_URL}/auth/admin/login`);
    url.searchParams.set("redirectUrl", redirectUrl || window.location.origin);
    return String(url);
  });

  const handleSubmit = async () => {
    if (status !== "idle") return;

    if (!email || email.length < 4 || !email.includes("@")) {
      snackbar.error("Email is invalid");
      return;
    }
    setStatus("loading");

    const url = new URL(`${process.env.GRAPH_URL}/auth/magic-link/login`);
    url.searchParams.set("email", email);
    url.searchParams.set("redirectUrl", redirectUrl || window.location.origin);
    await fetch(String(url), { method: "POST" })
      .then(() => {
        setStatus("success");
        snackbar.success("We sent the email");
      })
      .catch(() => {
        snackbar.error("Something went wrong, please reach campaign manager");
        setStatus("idle");
      });
  };
  return (
    <SLogin>
      <img src="/images/relatable_red.svg" alt="Relatable logo" style={{ height: 50 }} />

      <Typography>We will send you an email with a link to login.</Typography>
      <TextInput
        style={{ width: 350 }}
        onChange={setEmail}
        inputProps={{
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") handleSubmit();
          }
        }}
        value={email}
        label="Email"
        id="email"
      />
      <Button
        disabled={status === "success"}
        isLoading={status === "loading"}
        size="medium"
        onClick={handleSubmit}
      >
        {status === "success" ? "Sent!" : "Send me an email!"}
      </Button>
      <Link
        to={adminUrl}
        style={{ marginTop: "auto", cursor: "pointer", opacity: 0.5, fontSize: 10 }}
      >
        Are you an admin?
      </Link>

      <Accordion
        options={[
          {
            title: "How it works 🛠",
            content: (
              <Note label="Steps" variant="info">
                <ul>
                  {[
                    "Type your assigned with the project email to the input above",
                    "Click Send me an email",
                    "In a while you should gen an email with a special link, click on it or paste to the browser.",
                    "You should get redirected back to the application, logged in!"
                  ].map(i => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              </Note>
            )
          },
          {
            title: "Waiting for an email too long? ⌚",
            content: (
              <Note label="What can go wrong" variant="info">
                <ul>
                  {[
                    "There is a typo in your email",
                    "Your email is not assigned to any of our projects",
                    "Make sure with a campaign manager all is correctly set, maybe we experience temporary logging issues"
                  ].map(i => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              </Note>
            )
          }
        ]}
      />
    </SLogin>
  );
};

const SLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  margin: 40px;
  gap: 20px;
`;
