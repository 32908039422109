import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Avatar } from "@relatable/ui/Avatar";
import { palette } from "@relatable/ui/Palette";

import { ArrowDropDown } from "@mui/icons-material";
import type { ClientContactsQuery } from "../generated";

interface ExistingCollaboratorsContainerProps {
  project_client_contacts: ClientContactsQuery["project_client_contacts"];
}

export const ExistingCollaboratorsContainer = (
  props: ExistingCollaboratorsContainerProps
): JSX.Element => {
  const { project_client_contacts } = props;

  return (
    <Root defaultExpanded={false}>
      <StyledAccordionSummary
        expandIcon={<ArrowDropDown style={{ cursor: "pointer" }} fontSize="small" />}
      >
        Existing collaborators ({project_client_contacts.length})
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {project_client_contacts.map(c => (
          <div className="item" key={c.id}>
            <Avatar src={c.picture ?? "n/a"} size={24} />
            <span>
              {c.first_name} {c.last_name}
            </span>
          </div>
        ))}
      </StyledAccordionDetails>
    </Root>
  );
};

const Root = styled(Accordion)`
  && {
    background-color: ${palette.gray.whiteOff};
  }

  &.MuiAccordion-root.Mui-expanded {
    margin: 0px;
  }
  .MuiAccordionSummary-root {
    padding: 0px 24px;

    &.Mui-expanded {
      min-height: auto;
      font-weight: 700;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    display: grid;
    grid-template-columns: repeat(3, 32%);
    justify-content: space-between;
    grid-row-gap: 12px;
    padding: 0px 24px 12px;
  }

  > .item {
    display: flex;
    align-items: center;

    > span {
      margin-left: 8px;
    }
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    justify-content: flex-start;
  }
  .MuiAccordionSummary-content {
    flex-grow: 0;
  }
`;
