import styled from "@emotion/styled";

export const SidebarEmpty: React.FC = () => {
  return (
    <Root>
      <h4>How to review post</h4>
      <ul>
        <li>
          <strong>1.</strong> To approve the content, click on the content and/or caption box(es).{" "}
        </li>
        <li>
          <strong>2.</strong> You need to <b>&apos;Approve&apos;</b> or <b>&apos;Reject&apos;</b>{" "}
          each content piece including the caption. If you choose <b>&apos;Reject&apos;</b>, leave a
          comment explaining why it is rejected.{" "}
        </li>
        <li>
          <strong>3.</strong> When you have Approved or Rejected all the content, click the{" "}
          <b>Submit Review</b> button to share your status & comments with us.{" "}
        </li>
      </ul>
    </Root>
  );
};

const Root = styled.div`
  > ul {
    list-style: none;
    padding: 0 0 0 32px;
    > li {
      position: relative;
      margin: 20px 0;
      font-size: 16px;
      > strong {
        font-size: 20px;
        position: absolute;
        top: -4px;
        left: -32px;
      }
    }
  }
`;
