import type { FC } from "react";

import styled from "@emotion/styled";
import { ArrowForward, Check } from "@mui/icons-material";
import { Button } from "@relatable/ui/Button";

type ButtonProps = React.ComponentProps<typeof Button>;

export const SubmitReviewButton: FC<{
  listNeedsSubmit: boolean;
  variant?: ButtonProps["variant"];
  onSubmit: () => void;
  isSaving: boolean;
}> = ({ listNeedsSubmit = true, variant = "outlined", onSubmit, isSaving }) => (
  <Root
    icon={listNeedsSubmit || isSaving ? <ArrowForward /> : <Check />}
    variant={variant}
    onClick={onSubmit}
    disabled={!listNeedsSubmit}
    className={`${!isSaving && !listNeedsSubmit ? "flex-reverse" : ""}`}
  >
    {listNeedsSubmit || isSaving ? "Submit Review" : "Successfully Reviewed"}
  </Root>
);

const Root = styled(Button)`
  width: 220px;
  display: flex;
  gap: 10px;

  .MuiButton-label {
    > .icon {
      margin-left: 8px;
    }
  }

  &.flex-reverse {
    && {
      opacity: 0.25;
    }
    .MuiButton-label {
      flex-direction: row-reverse;
      > .icon {
        margin-right: 8px;
      }
    }
  }
`;
