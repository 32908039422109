import styled from "@emotion/styled";
import { clientApproved, normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";
import { useParams } from "react-router-dom";

import type { ContentDetailsQuery } from "../generated";
import { SidebarEmpty } from "./SidebarEmpty";
import { SidebarInfo } from "./SidebarInfo";
import { SidebarReview } from "./SidebarReview";
import { type ContentStateFragment, getContentState } from "./helpers";

interface SidebarProps {
  pmEmail: string | null;
  isStory: boolean;
  onUpdateState: (
    id: number,
    contentType: "media" | "caption",
    newClientState: CONTENT_APPROVAL_STATE
  ) => void;
  updateStateLoading: boolean;
  onAddComment: (id: number, contentType: "media" | "caption", text: string) => void;
  addCommentLoading: boolean;
  state: ContentStateFragment;
  medias: ContentDetailsQuery["contents"][0]["campaign_preapproval_content_medias"];
  caption: ContentDetailsQuery["contents"][0]["content_preapproval_caption"];
  numberOfPosts: number;
}

export const Sidebar: React.FC<SidebarProps> = ({
  pmEmail,
  isStory,
  onUpdateState,
  updateStateLoading,
  onAddComment,
  addCommentLoading,
  state,
  medias,
  caption,
  numberOfPosts
}) => {
  const { mediaId } = useParams<{ mediaId: string }>();

  const isOpen = Boolean(mediaId);
  const contentType = mediaId === "caption" ? "caption" : "media";

  const contentMediaItem =
    contentType === "caption" ? null : medias.find(m => m.id === Number(mediaId));

  const contentItem = contentType === "caption" ? caption : contentMediaItem;

  const numApprovedPosts = medias?.filter(media =>
    clientApproved(media.content_preapproval_logs[0]?.state)
  ).length;

  const contentState = normalizeState(contentItem?.content_preapproval_logs[0]?.state);

  return (
    <Root>
      <div className="info">
        <SidebarInfo
          pmEmail={pmEmail}
          isOpen={isOpen}
          isApproved={clientApproved(getContentState(state).state)}
          numApprovedPosts={numApprovedPosts}
          numRequiredPosts={numberOfPosts}
        />
        <div className="main">
          {isOpen && contentItem ? (
            <SidebarReview
              state={state}
              mediaState={contentState ?? null}
              logs={contentItem.logs}
              contentType={contentType}
              isStory={isStory}
              isVideo={contentType === "caption" ? false : (contentMediaItem?.isVideo ?? false)}
              updateStateLoading={updateStateLoading}
              onUpdateState={(newState: boolean) => {
                onUpdateState(
                  contentItem.id,
                  contentType,
                  newState
                    ? CONTENT_APPROVAL_STATE.CLIENT_APPROVED
                    : CONTENT_APPROVAL_STATE.CLIENT_REJECTED
                );
              }}
              addCommentLoading={addCommentLoading}
              onAddComment={(text: string) => onAddComment(contentItem.id, contentType, text)}
            />
          ) : (
            <SidebarEmpty />
          )}
        </div>
      </div>
    </Root>
  );
};

const Root = styled.div`
  > .info {
    margin: -24px;
    width: calc(100% + 60px);
    color: ${palette.gray[80]};
    > .main {
      padding: 16px 24px;
      > h4 {
        font-size: 20px;
      }
    }
  }
`;
