import styled from "@emotion/styled";
import { getPlatformLabel } from "@relatable/gql";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { PlayCircle } from "@mui/icons-material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import type { ContentThumbnail } from "modules/campaign/Creators/types";
import type { CreatorDataRow } from "modules/campaign/Creators/utils/creator_transform";

type ThumbnailContainerProps = {
  row: CreatorDataRow;
};

export const ThumbnailContainer: React.FC<ThumbnailContainerProps> = ({ row }) => {
  const { platform, platformName, thumbnails } = row;

  const thumbnailsToShow = [
    ...(thumbnails ?? []),
    ...(Array.from({ length: 9 }) as ContentThumbnail[])
  ].slice(0, 9);

  const hasThumbnails = thumbnails ? thumbnails.length > 0 : false;

  const renderThumbnail = (t: ContentThumbnail, index: number) => (
    <div className="thumbnail-wrapper" key={t?.thumbnailUrl || `${platformName}-${index}`}>
      {t?.thumbnailUrl ? (
        <Link to={t.postUrl} target="_blank" rel="noreferrer">
          {t.isVideo ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video className="thumbnail" src={t.thumbnailUrl} />
          ) : (
            <img className="thumbnail" src={t.thumbnailUrl} alt={`Post #${index}`} />
          )}
          {platform === "youtube" && <StyledVideoIcon />}
        </Link>
      ) : (
        <div className="activity" />
      )}
    </div>
  );

  return (
    <ImageContainer $platform={platform} $hasThumbnails={hasThumbnails}>
      {thumbnailsToShow ? thumbnailsToShow.map((t, index) => renderThumbnail(t, index)) : null}
      <Link className="view-more-link user-info" to={row.url} target="_blank" rel="noreferrer">
        View more on {getPlatformLabel(platform)}
      </Link>
    </ImageContainer>
  );
};

const ImageContainer = styled.div<{ $platform: Platform_Enum; $hasThumbnails: boolean }>`
  display: grid;

  ${({ $platform, $hasThumbnails }) => {
    if (!$hasThumbnails) {
      return "display: none;";
    }
    switch ($platform) {
      case "instagram":
        return "grid-template: repeat(3, 106px) / repeat(3, 106px)";
      case "tiktok":
        return "grid-template: repeat(3, 162px) / repeat(3, 106px)";
      case "youtube":
        return "grid-template: repeat(2, 106px) / repeat(2, 162px)";
      default:
        return "display: none;";
    }
  }};

  grid-gap: 4px;
  margin: 20px 0px;

  .thumbnail-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(211, 211, 211);
    z-index: 4;
    overflow: hidden;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 5;
  }

  @keyframes loading {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }

  .view-more-link {
    grid-column: 1 / -1;
    grid-row: 4 / 4;
    text-align: center;
    background-color: ${palette.gray.whiteOff};
    color: ${palette.gray.black};
    font-size: 13px;
    padding: 8px;

    &:hover {
      filter: brightness(90%);
    }
  }
`;

const StyledVideoIcon = styled(PlayCircle)`
  position: absolute;
  width: 24px;
  height: 24px;
  left: calc(50% - 12px);
  color: ${palette.gray.whiteOff};
  top: calc(50% - 12px);
`;
