import styled from "@emotion/styled";
import { Card } from "@mui/material";

interface InfoCardProps {
  children: React.ReactNode;
  className?: string;
}

export const InfoCard: React.FunctionComponent<InfoCardProps> = ({ children, className = "" }) => {
  return (
    <StyledCard className={className} variant="outlined">
      {children}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  && {
    position: relative;
    padding: 32px 52px;
    max-width: 700px;
    margin: 0 auto;
    font-size: 15px;
    border-radius: 8px;
    line-height: 25px;
  }
`;
