import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { CaptionStateFragmentDoc, MediaStateFragmentDoc } from '../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OverviewContentStateQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Campaign_Bool_Exp>;
}>;


export type OverviewContentStateQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, contents: Array<{ __typename?: 'campaign_preapproval_content', id: number, content_preapproval_caption?: { __typename?: 'content_preapproval_caption', id: number, content_preapproval_logs: Array<{ __typename?: 'content_preapproval_log', id: number, state?: string | null }> } | null, campaign_preapproval_content_medias: Array<{ __typename?: 'campaign_preapproval_content_media', id: number, content_preapproval_logs: Array<{ __typename?: 'content_preapproval_log', id: number, state?: string | null }> }> }> }> };


export const OverviewContentStateDocument = gql`
    query overviewContentState($where: campaign_bool_exp) {
  campaigns: campaign(where: $where, limit: 100) {
    id
    contents: campaign_preapproval_contents(
      where: {campaign_user: {state: {_in: ["approved", "confirmed"]}}}
    ) {
      id
      content_preapproval_caption {
        ...CaptionState
      }
      campaign_preapproval_content_medias(where: {deleted: {_eq: false}}) {
        ...MediaState
      }
    }
  }
}
    ${CaptionStateFragmentDoc}
${MediaStateFragmentDoc}`;

/**
 * __useOverviewContentStateQuery__
 *
 * To run a query within a React component, call `useOverviewContentStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewContentStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewContentStateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOverviewContentStateQuery(baseOptions?: Apollo.QueryHookOptions<OverviewContentStateQuery, OverviewContentStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewContentStateQuery, OverviewContentStateQueryVariables>(OverviewContentStateDocument, options);
      }
export function useOverviewContentStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewContentStateQuery, OverviewContentStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewContentStateQuery, OverviewContentStateQueryVariables>(OverviewContentStateDocument, options);
        }
export function useOverviewContentStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OverviewContentStateQuery, OverviewContentStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OverviewContentStateQuery, OverviewContentStateQueryVariables>(OverviewContentStateDocument, options);
        }
export type OverviewContentStateQueryHookResult = ReturnType<typeof useOverviewContentStateQuery>;
export type OverviewContentStateLazyQueryHookResult = ReturnType<typeof useOverviewContentStateLazyQuery>;
export type OverviewContentStateSuspenseQueryHookResult = ReturnType<typeof useOverviewContentStateSuspenseQuery>;
export type OverviewContentStateQueryResult = Apollo.QueryResult<OverviewContentStateQuery, OverviewContentStateQueryVariables>;