import { useCallback } from "react";

import styled from "@emotion/styled";
import { ErrorBoundary } from "@relatable/ui/ErrorBoundary";
import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

import { useReportFeErrorMutation } from "@relatable/gql/generated";
import { Header, NotFound, UseBiggerScreen } from "components/Layout";
import { Login } from "components/Login";
import { LoginCallback } from "components/LoginCallback";
import { useUser } from "hooks/useUser";
import { Admin } from "modules/admin";
import { Overview } from "modules/overview";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: block;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  height: calc(100% - 56px);
  width: 100%;
  position: relative;

  @media only screen and (min-width: 601px) {
    height: calc(100% - 64px);
  }
`;

const App: React.FC = () => {
  const user = useUser();
  const [mutate] = useReportFeErrorMutation();

  const errorHandler = useCallback(
    (error: string) => {
      mutate({
        variables: { url: window.location.href, message: error }
      });
    },
    [mutate]
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <UseBiggerScreen />
      <Root>
        <ErrorBoundary onError={errorHandler}>
          <ContentContainer>
            <Outlet />
          </ContentContainer>
        </ErrorBoundary>
      </Root>
    </>
  );
};

export const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route index element={<Navigate to="/overview" />} />
      <Route
        path="client/:hubspotCompanyId/*"
        element={
          <>
            <Header />
            <Overview />
          </>
        }
      />
      <Route
        path="overview/*"
        element={
          <>
            <Header />
            <Overview />
          </>
        }
      />
      <Route
        path="admin/*"
        element={
          <>
            <Header />
            <Admin />
          </>
        }
      />
      <Route path="login/callback" element={<LoginCallback />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
