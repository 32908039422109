import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

interface SidebarInfoProps {
  pmEmail: string | null;
  isApproved: boolean;
  isOpen: boolean;
  numRequiredPosts: number;
  numApprovedPosts: number;
}

export const SidebarInfo: React.FC<SidebarInfoProps> = ({
  pmEmail,
  isApproved,
  isOpen,
  numRequiredPosts,
  numApprovedPosts
}) => {
  if (isApproved) {
    return (
      <Root $type="warning">
        <h4>Your review was accepted, the content is going to be live.</h4>
        <h5>
          If you want to change your review, please{" "}
          <a href={pmEmail ? `mailto:${pmEmail}` : "#"} target="_blank" rel="noreferrer">
            <strong>contact your PM directly.</strong>
          </a>
        </h5>
      </Root>
    );
  }

  if (!isOpen) {
    return (
      <Root>
        <h5>You must review all details to proceed. </h5>
      </Root>
    );
  }

  if (numApprovedPosts < numRequiredPosts) {
    return (
      <Root $type="danger">
        <h4>You have not approved enough details to approve the post.</h4>
        <h5>Please submit review so we can continue to get the post correct.</h5>
      </Root>
    );
  }

  return (
    <Root $type="success">
      <h4>You&apos;ve approved enough details to approve the post.</h4>
      <h5>Please submit review so we can continue.</h5>
    </Root>
  );
};

const Root = styled.div<{ $type?: "success" | "danger" | "warning" }>`
  padding: 12px 24px;
  border-bottom: 1px solid ${palette.gray[20]};
  background: ${({ $type }) => {
    if ($type === "success") return palette.tertiary.green;
    if ($type === "danger") return palette.tertiary.gold;
    if ($type === "warning") return palette.gray[30];
    return palette.gray.white;
  }};

  > h4 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  > h5 {
    font-size: 14px;
    font-weight: normal;

    > a {
      color: ${palette.gray.black};
    }
  }
`;
