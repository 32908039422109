import type { FC } from "react";

import { Global } from "@emotion/react";
import { GqlProvider } from "@relatable/gql";
import { Loader } from "@relatable/ui/Loader";
import { UIProvider } from "@relatable/ui/UIProvider";
import { RouterProvider } from "react-router-dom";

import { appRouter } from "components/App";
import { gqlClient } from "components/gqlClient";

import { GlobalStyle } from "./lib/styles";

export const Root: FC = () => (
  <GqlProvider client={gqlClient}>
    <UIProvider>
      <Global styles={GlobalStyle} />
      <RouterProvider router={appRouter} fallbackElement={<Loader />} />
    </UIProvider>
  </GqlProvider>
);
