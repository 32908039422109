import { useState } from "react";

import styled from "@emotion/styled";
import { TableCell } from "@mui/material";
import { addCommasIfNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";

type ContentType = { value?: string; link?: string };

interface UserRowContentCellProps {
  content: ContentType; // JSON field in db
  id: string;
  isNumberCell: boolean;
}

export const UserRowContentCell = (props: UserRowContentCellProps): JSX.Element => {
  const { content = {} as ContentType, id, isNumberCell } = props;
  const [collapsed, setCollaped] = useState(true);

  const onSeeMoreClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setCollaped(false);
  };

  const onSeeLessClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setCollaped(true);
  };

  const renderContentElement = (c: string, link?: string | null) => {
    if (!link) {
      return <span>{c || "-"}</span>;
    }

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <span onClick={e => e.stopPropagation()}>
        <Link to={link ?? ""} target="_blank" rel="noreferrer">
          {c}
        </Link>
      </span>
    );
  };

  const renderContent = () => {
    const formattedContent = addCommasIfNumber(content.value ?? "");

    if (formattedContent.length < 30) {
      return renderContentElement(formattedContent, content.link);
    }

    if (!collapsed) {
      return (
        <span>
          {renderContentElement(formattedContent, content.link)}
          <br />
          <CollapseLink href="#" onClick={onSeeLessClick}>
            see less
          </CollapseLink>
        </span>
      );
    }

    return (
      <TruncateMarkup
        lines={3}
        ellipsis={
          <span>
            ...{" "}
            <CollapseLink href="#" onClick={onSeeMoreClick}>
              read more
            </CollapseLink>
          </span>
        }
      >
        {renderContentElement(formattedContent, content.link)}
      </TruncateMarkup>
    );
  };

  return (
    <StyledTableCell id={id} className={isNumberCell ? "number" : ""}>
      {renderContent()}
    </StyledTableCell>
  );
};

const StyledTableCell = styled(TableCell)`
  && {
    vertical-align: top;
    padding: 12px 16px;

    &.number {
      text-align: right;
    }

    > span {
      > a {
        color: ${palette.gray.black};
        font-weight: bold;
        &:hover {
          color: ${palette.gray[60]};
        }
      }
    }
  }
`;

const CollapseLink = styled.a`
  color: ${palette.gray[60]};
  font-weight: bold;
  &:hover {
    color: ${palette.gray[40]};
  }
`;
