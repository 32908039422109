import styled from "@emotion/styled";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { TableCell, TableSortLabel, Tooltip } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

interface HeadTableCellProps {
  className?: string;
  id: string;
  label?: string;
  orderBy?: string;
  order?: "desc" | "asc";
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  textAlign?: "left" | "right";
  onRequestSort?: (property) => void;
}

export const HeadTableCell: React.FunctionComponent<HeadTableCellProps> = ({
  className,
  id,
  label = "",
  orderBy,
  order,
  minWidth,
  maxWidth,
  width,
  textAlign = "left",
  onRequestSort
}) => {
  const formattedLabel = label?.replace(/_/g, " ");

  return (
    <Root
      className={className}
      style={{ minWidth, maxWidth, width, textAlign }}
      {...(order && { sortDirection: orderBy === id ? order : false })}
    >
      {order && orderBy !== undefined ? (
        <TableSortLabel
          active={orderBy === id}
          direction={orderBy === id ? order : "asc"}
          onClick={() => onRequestSort?.(id)}
          IconComponent={() =>
            order === "asc" ? (
              <ArrowDropDown
                style={{
                  marginRight: 8,
                  visibility: orderBy === id ? "unset" : "hidden",
                  position: "absolute",
                  right: -12
                }}
              />
            ) : (
              <ArrowDropUp
                style={{
                  marginRight: 8,
                  visibility: orderBy === id ? "unset" : "hidden",
                  position: "absolute",
                  right: -12
                }}
              />
            )
          }
        >
          <Tooltip
            enterDelay={250}
            title={formattedLabel}
            aria-label={formattedLabel.toLowerCase()}
            placement="top"
          >
            <span>{formattedLabel}</span>
          </Tooltip>
        </TableSortLabel>
      ) : (
        formattedLabel
      )}
    </Root>
  );
};

const Root = styled(TableCell)`
  && {
    color: ${palette.gray[60]};
    background: ${palette.gray.white};
    border-top: 1px solid ${palette.gray[30]};
    border-bottom: 1px solid ${palette.gray[30]};
    border-right: 1px solid ${palette.gray[30]};
    padding: 8px 16px;
    padding-right: 24px;

    > span {
      max-width: 200px;
    }

    &:first-child,
    &:last-child {
      border-right: 0;
    }
    &:hover > span {
      color: ${palette.gray[80]};
    }
  }

  > .MuiTableSortLabel-root {
    & {
      box-sizing: border-box;
    }
    > span {
      max-width: 320px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.MuiTableSortLabel-active {
      color: ${palette.gray.black};
      font-weight: bold;

      > .fa-sort-up {
        margin-top: 4px;
      }

      > .fa-sort-down {
        margin-top: -4px;
      }
    }
  }
`;
