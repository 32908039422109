import type { FC } from "react";

import type { Clients_Bool_Exp, InputMaybe } from "@relatable/gql/generated-base";
import { Loader } from "@relatable/ui/Loader";
import { Route, Routes, useParams } from "react-router-dom";

import { useClientsOverviewQuery } from "hooks/generated";
import { ProjectReview } from "modules/overview/review/ProjectReview";

import { Container } from "components/Layout";
import { CampaignContainer } from "../campaign/CampaignContainer";
import { ProjectTile } from "./ProjectTile";

export const Project: FC = () => {
  const { projectStub, hubspotCompanyId } = useParams<{
    hubspotCompanyId: string;
    projectStub: string;
  }>();

  const where: InputMaybe<Clients_Bool_Exp> = {};
  if (hubspotCompanyId) where.hubspot_company_id = { _eq: hubspotCompanyId };
  if (projectStub) where.projects = { stub: { _eq: projectStub } };
  const { data } = useClientsOverviewQuery({
    variables: {
      where
    }
  });

  if (!data?.clients) return null;

  const project = data.clients.flatMap(c => c.projects).find(cp => cp.stub === projectStub);

  if (!project)
    return (
      <Container>
        <Loader />
      </Container>
    );

  return (
    <Routes>
      <Route path="c/:campaignStub/*" element={<CampaignContainer />} />
      {(project.archived || project.is_ready_for_client_review) && (
        <Route path="review" element={<ProjectReview project={project} />} />
      )}

      <Route path="" element={<ProjectTile project={project} />} />
    </Routes>
  );
};
