import { type FC, useEffect } from "react";

import { Loader } from "@relatable/ui/Loader";
import { useParams } from "react-router-dom";

import { Container, NotFound } from "components/Layout";

import { Campaign } from "./Campaign";
import { useCampaignQuery } from "./generated";

export const CampaignContainer: FC = () => {
  const routeParams = useParams<{ campaignStub: string }>();

  const {
    loading,
    data: {
      campaign: [campaign] = []
    } = {}
  } = useCampaignQuery({
    variables: { stub: routeParams.campaignStub ?? "" }
  });

  useEffect(() => {
    document.title = campaign ? campaign.title : "Client";
  }, [campaign]);

  if (loading)
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (!campaign) return <NotFound />;

  return <Campaign campaign={campaign} />;
};
