import { type FC, useMemo, useState } from "react";

import styled from "@emotion/styled";
import type { SvgIconComponent } from "@mui/icons-material";
import { Button, debounce } from "@mui/material";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";

const Root = styled.div`
  > ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0 auto;
    margin-top: 18px;
    height: 175px;

    > li {
      display: block;

      > .button {
        position: relative;
        border: 2px solid ${palette.gray[10]};
        border-radius: 20px;
        width: 100px;
        height: 100px;
        font-size: 42px;

        &.inactive {
          opacity: 0.3;
        }

        &.active {
          border-color: ${palette.primary.red};
        }

        > .number {
          position: absolute;
          right: 7px;
          bottom: 7px;
          font-size: 12px;
          color: ${palette.gray[40]};
        }
      }

      > .title {
        max-width: 100px;
        margin: 15px 0;
        height: 36px;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
`;

export const ScoreStep: FC<{
  steps: { value: number; title: string; icon: SvgIconComponent }[];
  value: number | null | string;
  textInput: boolean;
  onChange(value: number | string): void;
}> = ({ steps, value, onChange, textInput }) => {
  const [inputValue, setInputValue] = useState(String(value));

  const debouncedOnChange = useMemo(
    () =>
      debounce((t: string) => {
        onChange(t);
      }, 1000),
    [onChange]
  );
  return (
    <Root>
      <ul>
        {textInput && (
          <TextInput
            multiline
            value={inputValue}
            style={{ width: "100%", margin: "20px 0" }}
            onChange={v => {
              setInputValue(v);
              debouncedOnChange(v);
            }}
          />
        )}
        {steps.map(step => (
          <li key={step.value}>
            <Button
              variant="text"
              className={`button ${step.value === value ? "active" : "inactive"}`}
              onClick={() => onChange(step.value)}
            >
              <step.icon />
              <span className="number">{step.value}</span>
            </Button>
            <div className="title">{step.title}</div>
          </li>
        ))}
      </ul>
    </Root>
  );
};
