import { type FC, useCallback, useEffect, useState } from "react";

import styled from "@emotion/styled";
import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import type {
  Campaign_User_Set_Input,
  Campaign_User_State_Enum
} from "@relatable/gql/generated-base";
import { palette } from "@relatable/ui/Palette";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { CreatorSidebar, HeadTableCell, TableActionBar, UserRow } from "./common";
import { LowQuota, NoCreatorsState, OnTrack, ShortOnQuota } from "./info";
import type { CreatorDataRow } from "./utils/creator_transform";

export type IFilterStateValue = "all" | "client_reviewing" | "client_declined" | "client_approved";
export type IFilterOptions = Record<IFilterStateValue, { label: string; count: number }>;

const validStates = [
  "client_reviewing",
  "client_approved",
  "client_declined",
  "approved",
  "rejected"
];

export const Creators: FC<{
  dueDate?: string;
  extraFields: { key: string; label: string; isNumberCell: boolean }[];
  isSaving: boolean;
  listNeedsSubmit: boolean;
  minCreators: number;
  onSubmitList: (feedback?: string) => void;
  rows: CreatorDataRow[];
  setCampaignUserState: (campaignUserId: number, state: Campaign_User_State_Enum) => void;
  submitClientListLoading: boolean;
  updateCampaignUser: (campaignUserId: number, data: Campaign_User_Set_Input) => void;
}> = ({
  dueDate,
  extraFields,
  isSaving = false,
  listNeedsSubmit,
  minCreators = 0,
  onSubmitList: onSubmitListProp,
  rows,
  setCampaignUserState,
  submitClientListLoading,
  updateCampaignUser
}) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("platformName");
  const [showLowOnQuota, setShowLowOnQuota] = useState(false);
  const [showCompletedInfo, setShowCompletedInfo] = useState(false);
  const [sidebarCampaignUserId, setSidebarCampaignUserId] = useState<number | null>(null);

  const filterCreators = (state: IFilterStateValue) =>
    rows.filter(row => {
      if (state === "all") return true;

      if (!validStates.includes(row.state as IFilterStateValue)) return false;

      if (state === "client_approved" && ["client_approved", "approved"].includes(row.state))
        return true;

      if (state === "client_declined" && ["client_declined", "rejected"].includes(row.state))
        return true;

      if (state === "client_reviewing" && row.state === "client_reviewing") return true;

      return false;
    });

  const filterOptions: IFilterOptions = {
    client_reviewing: { label: "Needs Review", count: filterCreators("client_reviewing").length },
    client_approved: { label: "Approved", count: filterCreators("client_approved").length },
    client_declined: { label: "Declined", count: filterCreators("client_declined").length },
    all: { label: "All", count: filterCreators("all").length }
  };

  const [filter, setFilter] = useState<IFilterStateValue>("client_reviewing");
  const creatorsToShow = filterCreators(filter);

  const [lastPendingCreators, setLastPendingCreators] = useState(
    filterOptions.client_reviewing.count
  );

  // used to trigger "Good on Quota" and "Short on Quota" dialogs
  useEffect(() => {
    if (filterOptions.client_reviewing.count === lastPendingCreators) return;
    if (filterOptions.client_reviewing.count === 0) {
      setShowCompletedInfo(true);
    }
    setLastPendingCreators(filterOptions.client_reviewing.count);
  }, [lastPendingCreators, filterOptions.client_reviewing.count]);

  const handleFilterChange = (f: typeof filter) => {
    setFilter(f);

    if (showCompletedInfo) {
      setShowCompletedInfo(false);
    }
  };

  const sortedCreators = creatorsToShow.sort(getComparator(order, orderBy));
  const openedRowIndex = sortedCreators.findIndex(
    sc => sc.campaignUserId === sidebarCampaignUserId
  );
  const prevCampaignUserId =
    openedRowIndex > 0 ? sortedCreators[openedRowIndex - 1]?.campaignUserId : null;
  const nextCampaignUserId =
    openedRowIndex < sortedCreators.length
      ? sortedCreators[openedRowIndex + 1]?.campaignUserId
      : null;

  const onSubmitList = (forceSubmit: boolean, feedback?: string) => {
    if (!forceSubmit && !showLowOnQuota && filterOptions.client_approved.count < minCreators) {
      setShowLowOnQuota(true);
      return;
    }
    onSubmitListProp(feedback);
  };

  // this is passed to UserRow -> needs useCallback in order to get memoized properly
  const onOpenSidebar = useCallback(
    (campaignUserId: number) => setSidebarCampaignUserId(campaignUserId),
    []
  );
  const onCloseSidebar = () => setSidebarCampaignUserId(null);

  const onRequestSort = property => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const orderProps = { order, orderBy, onRequestSort };

  const renderInfoComponent = () => {
    if (showCompletedInfo) {
      if (filterOptions.client_approved.count >= minCreators) {
        return (
          <OnTrack
            onSubmit={() => onSubmitList(true)}
            submitClientListLoading={submitClientListLoading}
            listNeedsSubmit={listNeedsSubmit}
          />
        );
      } else {
        return <ShortOnQuota min={minCreators} onDismiss={() => setShowCompletedInfo(false)} />;
      }
    } else if (sortedCreators.length === 0) {
      return <NoCreatorsState setFilterToAll={() => handleFilterChange("all")} />;
    }

    return null;
  };

  const sidebarRow = rows.find(sc => sc.campaignUserId === sidebarCampaignUserId);

  return (
    <Root>
      {isSaving ? (
        <SavingIndicator>
          Saving
          <CircularProgress style={{ marginLeft: 4 }} size={16} />
        </SavingIndicator>
      ) : null}

      <TableActionBar
        filter={filter}
        setFilter={handleFilterChange}
        dueDate={dueDate}
        filterOptions={filterOptions}
        minCreators={minCreators ?? 0}
        listNeedsSubmit={listNeedsSubmit}
        submitClientListLoading={submitClientListLoading}
        onSubmitList={onSubmitList}
        onCloseSidebar={onCloseSidebar}
      />
      {showCompletedInfo || sortedCreators.length === 0 ? (
        renderInfoComponent()
      ) : (
        <TableWrapper>
          <StyledTableContainer
            className={sidebarCampaignUserId ? "with-sidebar" : "without-sidebar"}
          >
            <StyledTable stickyHeader>
              <TableHead>
                <TableRow>
                  <LeftFixedHeadTableCell
                    id="platformName"
                    label="Creator"
                    width={200}
                    {...orderProps}
                  />

                  {extraFields.map(field => (
                    <HeadTableCell
                      key={field.key}
                      id={`clientData.${field.key}`}
                      label={field.label}
                      textAlign="left"
                      {...orderProps}
                    />
                  ))}

                  <RightFixedHeadTableCell
                    className={sidebarCampaignUserId ? "open" : "closed"}
                    id="state"
                    label="Status"
                    minWidth={sidebarCampaignUserId ? 225 : 445}
                    width={sidebarCampaignUserId ? 225 : 445}
                    {...orderProps}
                  />
                </TableRow>
              </TableHead>
              <StyledTableBody>
                <TransitionGroup component={null}>
                  {sortedCreators.map((row: CreatorDataRow) => (
                    <CSSTransition key={row.campaignUserId} timeout={500} classNames="table-row">
                      <UserRow
                        extraFields={extraFields}
                        row={row}
                        sidebarOpenWithCurrentRow={sidebarCampaignUserId === row.campaignUserId}
                        setCampaignUserState={setCampaignUserState}
                        onOpenSidebar={onOpenSidebar}
                        updateCampaignUser={updateCampaignUser}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>
          <div className="transitions">
            <CSSTransition in={Boolean(sidebarCampaignUserId)} timeout={500} classNames="width">
              <TransitionGroup>
                <CSSTransition key={sidebarCampaignUserId} timeout={500} classNames="fade">
                  {sidebarCampaignUserId && sidebarRow ? (
                    <CreatorSidebar
                      extraFields={extraFields}
                      key={sidebarCampaignUserId}
                      nextCampaignUserId={nextCampaignUserId ?? null}
                      onCloseSidebar={onCloseSidebar}
                      prevCampaignUserId={prevCampaignUserId ?? null}
                      row={sidebarRow}
                      setCampaignUserState={setCampaignUserState}
                      setSidebarCampaignUserId={setSidebarCampaignUserId}
                      updateCampaignUser={updateCampaignUser}
                    />
                  ) : (
                    <div />
                  )}
                </CSSTransition>
              </TransitionGroup>
            </CSSTransition>
          </div>

          <LowQuota
            current={filterOptions.client_approved.count}
            submitClientListLoading={submitClientListLoading}
            listNeedsSubmit={listNeedsSubmit}
            min={minCreators}
            onDismiss={() => setShowLowOnQuota(false)}
            onForceSubmit={feedback => onSubmitList(true, feedback)}
            open={showLowOnQuota}
            total={filterOptions.all.count}
          />
        </TableWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const StyledTable = styled(Table)``;

const TableWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  padding: 0 36px;
  align-items: flex-start;

  > .transitions {
    min-height: calc(100vh - 240px);

    .width-enter {
      width: 1px;
    }
    .width-exit {
      width: 416px;
    }
    .width-enter-active {
      width: 416px;
      transition: width 500ms;
    }
    .width-exit-active {
      width: 1px;
      transition: width 500ms;
    }

    .fade-enter {
      opacity: 0;
      position: absolute;
      top: 0;
    }
    .fade-exit {
      opacity: 1;
    }
    .fade-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }
    .fade-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }
  }
`;

const SavingIndicator = styled.span`
  position: absolute;
  top: -40px;
  right: 36px;
`;

const StyledTableContainer = styled(TableContainer)`
  && {
    max-height: calc(100vh - 240px);
    border-right: 1px solid ${palette.gray[30]};

    & td {
      border-color: ${palette.gray[30]};
    }

    .MuiTableCell-root {
      font-size: inherit;
    }

    .MuiTableCell-body {
      color: ${palette.gray.black};
    }

    /* these declarations have effect on child components in order
    to avoid rerendering all table rows when sidebar state changes */
    &.with-sidebar {
      .comment {
        width: auto;
      }
      .comment-field {
        display: none;
      }
      .right-cell {
        position: relative;
        /* hide border before comments section */
        &:after {
          border-left: 0;
        }
      }
    }

    &.without-sidebar {
      .right-cell {
        position: sticky;
        right: 0;
        width: 444px;
        z-index: 1;
        padding: 12px 16px;
        padding-right: 0;
      }
    }
  }
`;

const LeftFixedHeadTableCell = styled(HeadTableCell)`
  && {
    position: sticky;
    left: 0;
    z-index: 10;
    border-left: 1px solid ${palette.gray[30]};

    &:after {
      content: "";
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      border-right: 1px solid ${palette.gray[30]};
    }
  }
`;

const RightFixedHeadTableCell = styled(HeadTableCell)`
  && {
    &:before {
      content: "";
      left: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      border-left: 1px solid ${palette.gray[30]};
    }

    &.closed {
      position: sticky;
      right: 0;
      z-index: 2;

      &:after {
        content: "Client feedback";
        position: absolute;
        width: 200px;
        left: 152px;
        top: 0;
        bottom: 0;
        padding: 0 16px;
        border-left: 1px solid ${palette.gray[30]};
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
`;

const StyledTableBody = styled(TableBody)`
  && {
    .table-row-enter {
      opacity: 0;
    }
    .table-row-exit {
      opacity: 1;
    }
    .table-row-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }
    .table-row-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }
  }
`;

function getNumber(value: string | number) {
  if (typeof value === "number") return value;
  const sanitizedValue = value.replace(/\s+/g, "").trim();
  const num = Number(sanitizedValue);
  if (!Number.isNaN(num)) return num;
  if (sanitizedValue.endsWith("%")) {
    const percentage = Number(sanitizedValue.slice(0, -1));
    if (!Number.isNaN(percentage)) return percentage;
  }
  return null;
}

function getComparator(
  order: "asc" | "desc",
  orderByPath: string
): (a: CreatorDataRow, b: CreatorDataRow) => -1 | 0 | 1 {
  return (a: CreatorDataRow, b: CreatorDataRow) => {
    let aValue = a[orderByPath] || null;
    if (aValue && typeof aValue === "object" && aValue.value) {
      aValue = aValue.value;
    }

    let bValue = b[orderByPath] || null;
    if (bValue && typeof bValue === "object" && bValue.value) {
      bValue = bValue.value;
    }

    if (aValue === null && bValue === null) return 0;
    if (aValue && !bValue) {
      return order === "desc" ? -1 : 1;
    }
    if (bValue && !aValue) {
      return order === "desc" ? 1 : -1;
    }

    // try converting to number
    const aValueNum = getNumber(aValue);
    const bValueNum = getNumber(bValue);
    if (aValueNum !== null && bValueNum !== null) {
      const diff = aValueNum - bValueNum;
      return order === "desc" ? -diff : diff;
    }

    if (typeof aValue === "string") {
      return order === "desc" ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    }

    if (bValue < aValue) {
      return order === "desc" ? -1 : 1;
    }
    if (bValue > aValue) {
      return order === "desc" ? 1 : -1;
    }

    return 0;
  };
}
