import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";

import { InfoCard } from "components/ui/InfoCard";

interface ShortOnQuotaProps {
  min: number;
  onDismiss: () => void;
}

export const ShortOnQuota: React.FunctionComponent<ShortOnQuotaProps> = ({ min, onDismiss }) => {
  return (
    <Root>
      <InfoCard>
        <div className="title">
          <h1>Short on quota</h1>
          <div className="button">
            <Button variant="outlined" onClick={onDismiss}>
              Revisit Creators
            </Button>
          </div>
        </div>
        <p>
          You need to select at least {min} Creators to approve the list. Please revisit the list of
          Creators and change the statuses to approved to reach the minimum amount.
        </p>
      </InfoCard>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 72px;

  > .MuiCard-root {
    > .title {
      display: flex;
      justify-content: space-between;

      > h1 {
        font-size: 20px;
      }
    }
    > p {
      margin-bottom: 0;
    }
  }
`;
