import { type FC, useCallback, useEffect, useState } from "react";

import type {
  Campaign_User_Set_Input,
  Campaign_User_State_Enum
} from "@relatable/gql/generated-base";
import { isNumeric } from "@relatable/helpers";
import { Loader } from "@relatable/ui/Loader";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Container } from "components/Layout";
import { Creators } from "./Creators";
import {
  useCampaignCreatorsQuery,
  useSubmitClientListMutation,
  useUpdateCampaignUserMutation,
  useUpdateCampaignUserStateMutation
} from "./generated";
import { type CreatorDataRow, getCreatorDataRow } from "./utils/creator_transform";
import { areCreatorsUpdated } from "./utils/creator_update";

export const CreatorsContainer: FC<{
  campaignId: number;
}> = ({ campaignId }) => {
  const [listNeedsSubmit, setListNeedsSubmit] = useState(false);

  const {
    data: {
      campaign,
      users_in_lists: creators = [],
      campaign_client_sheet_latest: sheetData = [],
      campaign_client_sheet: remoteLastSync = []
    } = {},
    loading: getCreatorsLoading
  } = useCampaignCreatorsQuery({
    variables: { campaignId },
    onCompleted: res => {
      const latestSheet = res.campaign_client_sheet_latest[0];
      setListNeedsSubmit(latestSheet?.state === "CLIENT_REVIEWING");
    }
  });

  const [updateCampaignUserMutation, { loading: updateCampaignUserLoading }] =
    useUpdateCampaignUserMutation();

  const snackbar = useSnackbar();

  const lastSyncDate = remoteLastSync[0]?.created_at;
  useEffect(() => {
    if (!campaignId || !lastSyncDate || !campaign) return;
    if (areCreatorsUpdated(campaignId, lastSyncDate)) {
      snackbar.success(
        `New creator candidates are available to review on ${campaign.title} campaign.`
      );
    }
  }, [campaignId, lastSyncDate, campaign, snackbar]);

  const [submitClientListMutation, { loading: submitClientListLoading }] =
    useSubmitClientListMutation({
      onCompleted: () => {
        snackbar.success("Your review was successfully submitted. Your PM will be in contact.");
      },
      onError: () => {
        snackbar.error("Your submission had an error. Please try again or contact your PM.");
      }
    });

  // useCallback is needed here because this function is passed down to <UserRow /> and without it,
  // the debounced update of comments would be broken
  const updateCampaignUser = useCallback(
    (campaignUserId: number, data: Campaign_User_Set_Input) => {
      if (!campaignUserId) return;
      if (!listNeedsSubmit) setListNeedsSubmit(true);
      updateCampaignUserMutation({
        variables: { campaignUserId, _set: data },
        optimisticResponse: {
          update_campaign_user_by_pk: {
            ...data,
            id: campaignUserId,
            __typename: "campaign_user"
          }
        }
      });
    },
    [updateCampaignUserMutation, listNeedsSubmit]
  );

  const [setCampaignUserStateMutation, { loading: setCampaignUserStateLoading }] =
    useUpdateCampaignUserStateMutation();

  // useCallback is needed here because this function is passed down to <UserRow /> and without it,
  // the memoization of the status / comment fields would be broken
  const setCampaignUserState = useCallback(
    (campaignUserId: number, state: Campaign_User_State_Enum) => {
      if (!listNeedsSubmit) setListNeedsSubmit(true);
      const newStateLogEntry = {
        [state]: new Date().toISOString()
      } as { [key in Campaign_User_State_Enum]: string };
      setCampaignUserStateMutation({
        variables: { campaignUserId, state, log: newStateLogEntry },
        optimisticResponse: {
          update_campaign_user_by_pk: {
            state,
            state_log: newStateLogEntry,
            id: campaignUserId,
            __typename: "campaign_user"
          }
        }
      });
    },
    [setCampaignUserStateMutation, listNeedsSubmit]
  );

  const onSubmitList = (clientComment: string | undefined) => {
    setListNeedsSubmit(false);
    if (typeof clientComment === "string") {
      submitClientListMutation({
        variables: { campaignId, clientComment }
      });
    }
  };

  if (getCreatorsLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  const rows = creators
    .map(user => getCreatorDataRow(user))
    .filter(user => Boolean(user?.campaignUserId)) as CreatorDataRow[];

  const extraFields =
    campaign?.client_list_fields
      .filter(field => field.data_key.toLowerCase() !== "username")
      .map(field => ({
        key: field.data_key,
        label: field.data_label ?? field.data_key,
        isNumberCell: Boolean(
          rows.find(r => isNumeric(r.clientData?.[field.data_key]?.value ?? "null"))
        )
      })) ?? [];

  return (
    <Creators
      isSaving={updateCampaignUserLoading || setCampaignUserStateLoading || submitClientListLoading}
      submitClientListLoading={submitClientListLoading}
      extraFields={extraFields}
      minCreators={sheetData[0]?.min_creators ?? 0}
      dueDate={sheetData[0]?.due_date || ""}
      listNeedsSubmit={listNeedsSubmit}
      rows={rows}
      updateCampaignUser={updateCampaignUser}
      setCampaignUserState={setCampaignUserState}
      onSubmitList={onSubmitList}
    />
  );
};
