import { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { MMM_DD, prettifyDate, uniqBy, unique } from "@relatable/helpers/index";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { Link, useParams } from "react-router-dom";

import type { CampaignsOverviewQuery } from "../generated";
import { CONTENT_APPROVAL_STATE_CLIENT_LABELS, getContentState, getTypeLabel } from "./helpers";

type CampaignUsers = CampaignsOverviewQuery["campaigns"][0]["campaign_users"];

interface HeaderProps {
  briefUrl: string;
  campaignUsers: CampaignUsers;
  onChangeFilterFn: (fn: (campaignUsers: CampaignUsers) => CampaignUsers) => void;
}

export const Header: React.FC<HeaderProps> = ({ briefUrl, campaignUsers, onChangeFilterFn }) => {
  const { contentId } = useParams<{ contentId: string }>();

  const dueDate = (() => {
    if (!contentId) return "";
    const user = campaignUsers.find(cu =>
      cu.campaign_preapproval_contents.find(c => c.id === Number(contentId))
    );
    const content = user?.campaign_preapproval_contents.find(c => c.id === Number(contentId));
    return content?.content_settings?.preapproval_deadline || "";
  })();

  const [scheduledTimeSelection, setScheduledTimeSelection] = useState("ALL");
  const [postTypeSelection, setPostTypeSelection] = useState("ALL");
  const [stateSelection, setStateSelection] = useState("ALL");

  type NonNullableContentSettingItem = NonNullable<
    CampaignUsers[number]["campaign_preapproval_contents"][number]["content_settings"]
  >;

  const uniqueContentSettings = uniqBy(
    campaignUsers
      .flatMap(cu => cu.campaign_preapproval_contents.map(content => content.content_settings))
      .filter(cu => Boolean(cu)),
    "id"
  ).filter(cu => Boolean(cu)) as NonNullableContentSettingItem[];

  const uniqueScheduleTimes = uniqBy(
    uniqueContentSettings,
    setting => `${setting.publish_date_min} - ${setting.publish_date_max}`
  );

  const getScheduleKey = setting => `${setting.publish_date_min}-${setting.publish_date_max}`;

  const scheduledTimeOptions = [
    { label: "All scheduled times", value: "ALL" },
    ...uniqueScheduleTimes.map(setting => ({
      label: [MMM_DD(setting.publish_date_min), " - ", MMM_DD(setting.publish_date_max)].join(""),
      value: getScheduleKey(setting)
    }))
  ];

  const uniquePostTypes = uniqBy(uniqueContentSettings, "type");
  const postTypeOptions = [
    { label: "All post types", value: "ALL" },
    ...uniquePostTypes.map(setting => ({
      label: getTypeLabel(setting.type),
      value: setting.type
    }))
  ];

  const aggregateState = campaignUsers.flatMap(cu =>
    cu.campaign_preapproval_contents.map(content => getContentState(content).state)
  );

  const uniqueContentStates = unique(aggregateState);
  const stateOptions = [
    { label: "All states", count: aggregateState.length, value: "ALL" },
    ...uniqueContentStates.map(stateName => ({
      label: CONTENT_APPROVAL_STATE_CLIENT_LABELS[stateName],
      value: stateName,
      count: aggregateState.filter(item => item === stateName).length
    }))
  ];

  // create filter function
  useEffect(() => {
    const filterFn = (users: CampaignUsers): CampaignUsers => {
      const condition = (c: CampaignUsers[0]["campaign_preapproval_contents"][0]) =>
        (scheduledTimeSelection === "ALL" ||
          getScheduleKey(c.content_settings) === scheduledTimeSelection) &&
        (postTypeSelection === "ALL" || c.content_settings?.type === postTypeSelection) &&
        (stateSelection === "ALL" || getContentState(c).state === stateSelection);

      return users
        .map(u => ({
          ...u,
          campaign_preapproval_contents: u.campaign_preapproval_contents.filter(c => condition(c))
        }))
        .filter(u => u.campaign_preapproval_contents.length);
    };
    onChangeFilterFn(filterFn);
  }, [onChangeFilterFn, scheduledTimeSelection, postTypeSelection, stateSelection]);

  return (
    <Root>
      <div className="left">
        <div className="date">{dueDate && prettifyDate(dueDate)}</div>
        <div className="title">
          <h2>Content Approval</h2>
          <h3>Approve your creators&apos; custom-made content.</h3>
        </div>
      </div>
      <div className="right">
        <Link to={briefUrl} target="_blank" rel="noreferrer">
          View Brief
        </Link>
        <Select
          hideNone
          label="Scheduled times"
          options={scheduledTimeOptions}
          value={scheduledTimeSelection}
          onChange={e => setScheduledTimeSelection(e as string)}
        />
        <Select
          hideNone
          label="Post types"
          options={postTypeOptions}
          value={postTypeSelection}
          onChange={e => setPostTypeSelection(e as string)}
        />
        <Select
          hideNone
          label="State options"
          options={stateOptions}
          value={stateSelection}
          onChange={e => setStateSelection(e as string)}
        />
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;

  > .left {
    > .date {
      text-transform: uppercase;
      font-size: 12px;
    }
    > .title {
      > h2 {
        display: inline-block;
        font-size: 20px;
        margin-right: 8px;
      }
      > h3 {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }

  > .right {
    display: flex;
    gap: 10px;
    > a {
      color: ${palette.gray[60]};
      border-right: 1px solid ${palette.gray[20]};
      margin-right: 10px;
      display: flex;
      align-items: center;
      padding-right: 20px;
      &:hover {
        color: ${palette.gray[80]};
      }
    }
  }
`;
