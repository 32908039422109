import { type FC, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Alert, Link } from "@mui/material";

import { Loader } from "@relatable/ui/Loader";
import { useSearchParams } from "@relatable/ui/hooks/useSearchParams";

export const LoginCallback: FC = () => {
  const { id, redirectUrl } = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;
    const login = async () => {
      setLoading(true);
      fetch(`${process.env.GRAPH_URL}/auth/magic-link/validate`, {
        method: "POST",
        body: JSON.stringify({ id }),
        headers: { "content-type": "application/json" },
        credentials: "include"
      })
        .then(r => r.json())
        .then(res => {
          if (!res?.ok) {
            setError(res?.message ?? "Unknown error!");
            setLoading(false);
          } else {
            const url = redirectUrl ?? "/";
            // it needs to reload the window to get the new cookies
            // loading is not set to false deliberately because the user needs to wait for the reload to happen
            window.location.href = url;
          }
        })
        .catch(err => {
          setError(err.message);
          setLoading(false);
        });
    };
    login();
  }, [id, redirectUrl]);

  return (
    <SLogin>
      {loading && <Loader />}
      {!id && (
        <Alert variant="filled" severity="error">
          Invalid URL!
        </Alert>
      )}

      {error ? (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      ) : null}

      {error || !id ? (
        <>
          <br />
          <Alert>
            Try logging in again <Link href="/login">here</Link>.
          </Alert>
        </>
      ) : null}
    </SLogin>
  );
};

const SLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  margin: 50px;
`;
