import { type FC, memo, useCallback, useMemo } from "react";

import styled from "@emotion/styled";
import { Box, TableCell, TableRow, debounce } from "@mui/material";
import type {
  Campaign_User_Set_Input,
  Campaign_User_State_Enum
} from "@relatable/gql/generated-base";
import { Avatar } from "@relatable/ui/Avatar";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import { Check, Close } from "@mui/icons-material";

import { PlatformIcon } from "@relatable/ui/src/icons/PlatformIcon";

import type { CreatorDataRow } from "../utils/creator_transform";
import { StateChangeFab } from "./StateChangeFab";
import { StatusField } from "./StatusField";
import { UserRowCommentField } from "./UserRowCommentField";
import { UserRowContentCell } from "./UserRowContentCell";

export const NO_COMMENT_ON_REJECTION_MESSAGE = "Please add a comment before declining";

export const UserRow: FC<{
  row: CreatorDataRow;
  extraFields: { key: string; label: string; isNumberCell: boolean }[];
  sidebarOpenWithCurrentRow: boolean;
  onOpenSidebar: (campaignUserId: number) => void;
  updateCampaignUser: (campaignUserId: number, data: Campaign_User_Set_Input) => void;
  setCampaignUserState: (campaignUserId: number, state: Campaign_User_State_Enum) => void;
}> = memo(
  ({
    extraFields,
    row,
    sidebarOpenWithCurrentRow,
    onOpenSidebar,
    setCampaignUserState,
    updateCampaignUser
  }) => {
    const { campaignUserId } = row;

    const setUser = useCallback(
      (data: Campaign_User_Set_Input) =>
        campaignUserId ? updateCampaignUser(campaignUserId, data) : null,
      [updateCampaignUser, campaignUserId]
    );
    const debouncedSetUser = useMemo(() => debounce(setUser, 200), [setUser]);

    const hasClientComment = (row.clientComment ?? "") !== "";

    // passed to memoized <StatusField />
    const onSetUserState = useCallback(
      (state: Campaign_User_State_Enum) =>
        campaignUserId ? setCampaignUserState(campaignUserId, state) : null,
      [campaignUserId, setCampaignUserState]
    );

    const renderInfluencerItem = () => (
      <Box
        className="influencer-item"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Avatar className="avatar" src={row.profilePicture} size={32} />
        <strong style={{ marginLeft: 8, color: "inherit" }}>@{row.platformName}</strong>
        <Button variant="text" href={row.url} target="_blank" style={{ minWidth: "auto" }}>
          <PlatformIcon platform={row.platform} size="sm" className="platform-icon" />
        </Button>
      </Box>
    );

    if (!campaignUserId) return null;

    return (
      <Root
        tabIndex={-1}
        className={`${sidebarOpenWithCurrentRow ? "active" : ""}`}
        onClick={() => onOpenSidebar(campaignUserId)}
      >
        <LeftFixedTableCell width={200} scope="row">
          {renderInfluencerItem()}
        </LeftFixedTableCell>

        {extraFields.map(field => (
          <UserRowContentCell
            isNumberCell={field.isNumberCell}
            key={field.key}
            id={`field.${field.key}`}
            content={row.clientData?.[field.key] ?? { value: "" }}
          />
        ))}

        <RightFixedTableCell className="right-cell">
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
          {/* biome-ignore lint/a11y/useSemanticElements: <explanation> */}
          <div className="wrapper" role="rowgroup" onClick={e => e.stopPropagation()}>
            <div className="status">
              <StatusField
                setUserState={onSetUserState}
                userState={row.state}
                hasClientComment={hasClientComment}
                isStateEditable={row.isStateEditable}
              />
            </div>
            <div className="comment">
              <UserRowCommentField
                platformName={row.platformName}
                value={row.clientComment ?? ""}
                onChange={cc => debouncedSetUser({ client_comment: cc })}
                disabled={!row.isStateEditable}
              />
              {row.state === "client_reviewing" && row.isStateEditable ? (
                <div className="buttons">
                  <StateChangeFab
                    title="Approve"
                    handleOnClick={() => setCampaignUserState(campaignUserId, "client_approved")}
                    Icon={Check}
                    noBackground
                    className="approve"
                  />

                  <StateChangeFab
                    title={hasClientComment ? "Decline" : NO_COMMENT_ON_REJECTION_MESSAGE}
                    handleOnClick={() => setCampaignUserState(campaignUserId, "client_declined")}
                    Icon={Close}
                    noBackground
                    disabled={!hasClientComment}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </RightFixedTableCell>
      </Root>
    );
  }
);

const Root = styled(TableRow)`
  background-color: ${palette.gray.white};
  cursor: pointer;

  &.active > td {
    background-color: ${palette.gray.whiteOff};
  }

  &:hover > td {
    background: ${palette.gray.whiteOff};
  }

  td {
    background: ${palette.gray.white};
    padding: 8px;
    border-right: 1px solid ${palette.gray[30]};

    &:first-child,
    &:last-child {
      border-right: 0;
    }
  }

  .platform-icon {
    color: ${palette.gray[60]};
    vertical-align: sub;
  }
`;

const LeftFixedTableCell = styled(TableCell)`
  && {
    position: sticky;
    left: 0;
    z-index: 1;
    border-left: 1px solid ${palette.gray[30]};
    vertical-align: top;
    padding: 12px 16px;

    &:after {
      content: "";
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      border-right: 1px solid ${palette.gray[30]};
    }
  }

  > .influencer-item {
    min-height: 32px;
    align-items: center;

    > .avatar {
      align-items: center;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

const RightFixedTableCell = styled(TableCell)`
  && {
    position: relative;
    vertical-align: top;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 152px;
      border-left: 1px solid ${palette.gray[30]};
    }

    > .wrapper {
      display: flex;
      align-items: flex-start;

      &:before {
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        border-left: 1px solid ${palette.gray[30]};
      }

      > .status {
        width: 136px;
      }

      > .comment {
        display: flex;
        align-items: flex-start;
        width: 280px;

        > .buttons {
          display: flex;
          > .approve {
            margin-right: 16px;
          }
        }

        /* make clickable area larger */
        > .MuiFormControl-root {
          min-height: 32px;
          > .MuiInputBase-root {
            min-height: 32px;
          }
        }
      }
    }

    > .transparent {
      background-color: inherit;
    }
  }
`;
