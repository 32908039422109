import styled from "@emotion/styled";

import { InfoCard } from "components/ui/InfoCard";

import { SubmitReviewButton } from "../common";

interface OnTrackProps {
  onSubmit: () => void;
  listNeedsSubmit: boolean;
  submitClientListLoading: boolean;
}

export const OnTrack: React.FunctionComponent<OnTrackProps> = ({
  onSubmit,
  listNeedsSubmit,
  submitClientListLoading
}) => {
  return (
    <Root>
      <InfoCard>
        <div className="title">
          <h1>You&apos;re on the right track</h1>
          <div className="button">
            <SubmitReviewButton
              variant="outlined"
              onSubmit={onSubmit}
              listNeedsSubmit={listNeedsSubmit}
              isSaving={submitClientListLoading}
            />
          </div>
        </div>
        <p>
          You&apos;ve selected enough potential Creators so we`re excited. Simply submit and our
          team will be in touch with next steps.
        </p>
      </InfoCard>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 72px;

  > .MuiCard-root {
    > .title {
      display: flex;
      justify-content: space-between;

      > h1 {
        font-size: 20px;
      }
    }
    > p {
      margin-bottom: 0;
    }
  }
`;
